
import axios from 'axios';
import store from '../redux/Store';
import ErrorResponseCode from '../data/ErrorResponseCode';
import { showDialogChange } from '../redux/actions/TwoFAActions';
import { logout } from '../redux/actions/UserActions';
import RefreshToken from '../functions/RefreshToken';

const CallApi = ({ headers, token, ...options }) => {

    let { UserReducer: userData } = store.getState();

    const promise = new Promise(async (resolve, reject) => {
        try {
            const res = await axios({
                timeout: 45000,
                headers: Boolean(token) && { ...headers, Authorization: `Bearer ${userData.access}` },
                ...options,
            });

            resolve(res.data);
        } catch (error) {
            let responseStatus = error?.response?.status;

            if (responseStatus === ErrorResponseCode.logout) store.dispatch(logout());
            if (responseStatus === ErrorResponseCode.timeout) store.dispatch(RefreshToken(true));
            if (responseStatus === ErrorResponseCode.twoFARequired) store.dispatch(showDialogChange(true));

            reject(error);
        };
    });

    return promise;
};

export default CallApi;
