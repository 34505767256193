
import CryptoDetailTypes from '../types/CryptoDetailTypes';

export const setCryptoDetailList = (cryptoDetailList, favoriteList) => {
    return {
        type: CryptoDetailTypes.setCryptoDetailList,
        cryptoDetailList,
        favoriteList,
    };
};

export const updateCryptoDetailList = (cryptoPricelList, favoriteList) => {
    return {
        type: CryptoDetailTypes.updateCryptoDetailList,
        cryptoPricelList,
        favoriteList,
    };
};

export const errorCryptoDetail = (error) => {
    return {
        type: CryptoDetailTypes.errorCryptoDetail,
        error,
    };
};

export const changeFavorite = (pairCurrency, isFavorite) => {
    return {
        type: CryptoDetailTypes.changeFavorite,
        pairCurrency,
        isFavorite,
    };
};
