
import React from 'react';
import classes from './UsernameFormatter.module.scss';
import classnames from 'classnames';

const UsernameFormatter = ({ className, username }) => {

    let isEmail = username?.includes('@');
    let finalUsername = '';

    if (isEmail) {
        let splittedEmail = username.split('@');
        finalUsername = splittedEmail[0].slice(0, 3) + '****@' + splittedEmail[1];
    } else {
        finalUsername = username.slice(-4) + '****' + username.slice(0, 3);
    };

    return (
        <div className={classnames(classes.main, className)}>
            {finalUsername}
        </div>
    );
};

export default UsernameFormatter;
