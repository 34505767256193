
const consts = {
    irtDecimal: 0,
    usdDecimal: 2,
    confirmFeeDecimal: 3,

    valuableFraction: 6,
    shitCoinFraction: 4,
    bitcoinValue: Math.pow(10, -6),
    defaultBitcoinPrice: 50000,
    percentDecimalFee: 3,

    payaCycleIrt: 50000000,
    maxConvertUSDT: 50000,
    maxFillBTC: process.env.REACT_APP_CHART_FILL_BTC,
};

export default consts;