
import IRT from '../assets/images/currencies/IRT.png';
import BTC from '../assets/images/currencies/BTC.png';
import ETH from '../assets/images/currencies/ETH.png';
import USDT from '../assets/images/currencies/USDT.png';
import XRP from '../assets/images/currencies/XRP.png';
import BNB from '../assets/images/currencies/BNB.png';
import DOT from '../assets/images/currencies/DOT.png';
import ADA from '../assets/images/currencies/ADA.png';
import LTC from '../assets/images/currencies/LTC.png';
import LINK from '../assets/images/currencies/LINK.png';
import XLM from '../assets/images/currencies/XLM.png';
import BCH from '../assets/images/currencies/BCH.png';
import BSV from '../assets/images/currencies/BSV.png';
import TRX from '../assets/images/currencies/TRX.png';
import ATOM from '../assets/images/currencies/ATOM.png';
import YFI from '../assets/images/currencies/YFI.png';
import AAVE from '../assets/images/currencies/AAVE.png';
import DASH from '../assets/images/currencies/DASH.png';
import VET from '../assets/images/currencies/VET.png';
import ETC from '../assets/images/currencies/ETC.png';
import ZEC from '../assets/images/currencies/ZEC.png';
import DOGE from '../assets/images/currencies/DOGE.png';
import BAND from '../assets/images/currencies/BAND.png';
import UNI from '../assets/images/currencies/UNI.png';
import XEM from '../assets/images/currencies/XEM.png';
import SHIB from '../assets/images/currencies/SHIB.png';
import BUSD from '../assets/images/currencies/BUSD.png';
import BTCBULL from '../assets/images/currencies/BTCBULL.png';
import BTCBEAR from '../assets/images/currencies/BTCBEAR.png';
import ETHBULL from '../assets/images/currencies/ETHBULL.png';
import ETHBEAR from '../assets/images/currencies/ETHBEAR.png';
import SOL from '../assets/images/currencies/SOL.png';
import EOS from '../assets/images/currencies/EOS.png';
import TBTC from '../assets/images/currencies/TBTC.png';
import TLTC from '../assets/images/currencies/TLTC.png';

export const currenciesData = {
    IRT: {
        name_en: "Toman",
        name_fa: "تومان",
        symbol: "IRT",
        logoimage: IRT,
    },
    BTC: {
        name_en: "Bitcoin",
        name_fa: "بیت‌کوین",
        symbol: "BTC",
        logoimage: BTC,
    },
    ETH: {
        name_en: "Ethereum",
        name_fa: "اتریوم",
        symbol: "ETH",
        logoimage: ETH,
    },
    USDT: {
        name_en: "Tether",
        name_fa: "تتر",
        symbol: "USDT",
        logoimage: USDT,
    },
    XRP: {
        name_en: "Ripple",
        name_fa: "ریپل",
        symbol: "XRP",
        logoimage: XRP,
    },
    BNB: {
        name_en: "Binance Coin",
        name_fa: "بایننس‌کوین",
        symbol: "BNB",
        logoimage: BNB,
    },
    DOT: {
        name_en: "Polkadot",
        name_fa: "پولکادات",
        symbol: "DOT",
        logoimage: DOT,
    },
    ADA: {
        name_en: "Cardano",
        name_fa: "کاردانو",
        symbol: "ADA",
        logoimage: ADA,
    },
    LTC: {
        name_en: "Litecoin",
        name_fa: "لایت‌کوین",
        symbol: "LTC",
        logoimage: LTC,
    },
    LINK: {
        name_en: "Chain Link",
        name_fa: "چین‌لینک",
        symbol: "LINK",
        logoimage: LINK,
    },
    XLM: {
        name_en: "Stellar",
        name_fa: "استلار",
        symbol: "XLM",
        logoimage: XLM,
    },
    BCH: {
        name_en: "Bitcoin Cash",
        name_fa: "بیت‌کوین کش",
        symbol: "BCH",
        logoimage: BCH,
    },
    BSV: {
        name_en: "Bitcoin SV",
        name_fa: "بیت‌کوین اس وی",
        symbol: "BSV",
        logoimage: BSV,
    },
    TRX: {
        name_en: "Tron",
        name_fa: "ترون",
        symbol: "TRX",
        logoimage: TRX,
    },
    ATOM: {
        name_en: "Cosmos",
        name_fa: "کازماس",
        symbol: "ATOM",
        logoimage: ATOM,
    },
    YFI: {
        name_en: "yearn.finance",
        name_fa: "یرن فایننس",
        symbol: "YFI",
        logoimage: YFI,
    },
    AAVE: {
        name_en: "Aave",
        name_fa: "آوه",
        symbol: "AAVE",
        logoimage: AAVE,
    },
    DASH: {
        name_en: "Dash",
        name_fa: "دش",
        symbol: "DASH",
        logoimage: DASH,
    },
    VET: {
        name_en: "VeChain",
        name_fa: "وی‌چین",
        symbol: "VET",
        logoimage: VET,
    },
    ETC: {
        name_en: "Ethereum Classic",
        name_fa: "اتریوم کلاسیک",
        symbol: "ETC",
        logoimage: ETC,
    },
    ZEC: {
        name_en: "Zcash",
        name_fa: "زی‌کش",
        symbol: "ZEC",
        logoimage: ZEC,
    },
    DOGE: {
        name_en: "Dogecoin",
        name_fa: "دوج‌کوین",
        symbol: "DOGE",
        logoimage: DOGE,
    },
    BAND: {
        name_en: "Band Protocol",
        name_fa: "بند پروتکل",
        symbol: "BAND",
        logoimage: BAND,
    },
    UNI: {
        name_en: "Uniswap",
        name_fa: "یونی‌سواپ",
        symbol: "UNI",
        logoimage: UNI,
    },
    XEM: {
        name_en: "NEM",
        name_fa: "نم",
        symbol: "XEM",
        logoimage: XEM,
    },
    SHIB: {
        name_en: "SHIBA INU",
        name_fa: "شیبا",
        symbol: "SHIB",
        logoimage: SHIB,
    },
    BUSD: {
        name_en: "Binance USD",
        name_fa: "بایننس یو‌اس‌دی",
        symbol: "BUSD",
        logoimage: BUSD,
    },
    BULL: {
        name_en: "Bull",
        name_fa: "بیت‌کوین بول",
        symbol: "BULL",
        logoimage: BTCBULL,
    },
    BEAR: {
        name_en: "Bear",
        name_fa: "بیت‌کوین بیر",
        symbol: "BEAR",
        logoimage: BTCBEAR,
    },
    ETHBULL: {
        name_en: "Ethereum Bull",
        name_fa: "اتریوم بول",
        symbol: "ETHBULL",
        logoimage: ETHBULL,
    },
    ETHBEAR: {
        name_en: "Ethereum Bear",
        name_fa: "اتریوم بیر",
        symbol: "ETHBEAR",
        logoimage: ETHBEAR,
    },
    EOS: {
        name_en: "Eos",
        name_fa: "ایاس",
        symbol: "EOS",
        logoimage: EOS,
    },
    SOL: {
        name_en: "Solana",
        name_fa: "سولانا",
        symbol: "SOL",
        logoimage: SOL,
    },
    TBTC: {
        name_en: "Bitcoin Test",
        name_fa: "بیت‌کوین تست",
        symbol: "TBTC",
        logoimage: TBTC,
    },
    TLTC: {
        name_en: "Litecoin Test",
        name_fa: "لایت‌کوین تست",
        symbol: "TLTC",
        logoimage: TLTC,
    },
};

export const currenciesPrimary = [
    {
        name_en: "Toman",
        name_fa: "تومان",
        symbol: "IRT",
        logoimage: IRT,
        usdt_price: 0,
        irt_price: 0,
        suspend_trade: false
    },
    {
        name_en: "Bitcoin",
        name_fa: "بیت‌کوین",
        symbol: "BTC",
        logoimage: BTC,
        usdt_price: 0,
        irt_price: 0,
        suspend_trade: false
    },
    {
        name_en: "Ethereum",
        name_fa: "اتریوم",
        symbol: "ETH",
        logoimage: ETH,
        usdt_price: 0,
        irt_price: 0,
        suspend_trade: false
    },
    {
        name_en: "Tether",
        name_fa: "تتر",
        symbol: "USDT",
        logoimage: USDT,
        usdt_price: 0,
        irt_price: 0,
        suspend_trade: false
    },
    {
        name_en: "Ripple",
        name_fa: "ریپل",
        symbol: "XRP",
        logoimage: XRP,
        usdt_price: 0,
        irt_price: 0,
        suspend_trade: false
    },
    {
        name_en: "Binance Coin",
        name_fa: "بایننس‌کوین",
        symbol: "BNB",
        logoimage: BNB,
        usdt_price: 0,
        irt_price: 0,
        suspend_trade: false
    },
    {
        name_en: "Polkadot",
        name_fa: "پولکادات",
        symbol: "DOT",
        logoimage: DOT,
        usdt_price: 0,
        irt_price: 0,
        suspend_trade: false
    },
    {
        name_en: "Cardano",
        name_fa: "کاردانو",
        symbol: "ADA",
        logoimage: ADA,
        usdt_price: 0,
        irt_price: 0,
        suspend_trade: false
    },
    {
        name_en: "Litecoin",
        name_fa: "لایت‌کوین",
        symbol: "LTC",
        logoimage: LTC,
        usdt_price: 0,
        irt_price: 0,
        suspend_trade: false
    },
    {
        name_en: "Chain Link",
        name_fa: "چین‌لینک",
        symbol: "LINK",
        logoimage: LINK,
        usdt_price: 0,
        irt_price: 0,
        suspend_trade: false
    },
    {
        name_en: "Stellar",
        name_fa: "استلار",
        symbol: "XLM",
        logoimage: XLM,
        usdt_price: 0,
        irt_price: 0,
        suspend_trade: false
    },
    {
        name_en: "Bitcoin Cash",
        name_fa: "بیت‌کوین کش",
        symbol: "BCH",
        logoimage: BCH,
        usdt_price: 0,
        irt_price: 0,
        suspend_trade: false
    },
    {
        name_en: "Bitcoin SV",
        name_fa: "بیت‌کوین اس وی",
        symbol: "BSV",
        logoimage: BSV,
        usdt_price: 0,
        irt_price: 0,
        suspend_trade: false
    },
    {
        name_en: "Tron",
        name_fa: "ترون",
        symbol: "TRX",
        logoimage: TRX,
        usdt_price: 0,
        irt_price: 0,
        suspend_trade: false
    },
    {
        name_en: "Cosmos",
        name_fa: "کاس‌مموس",
        symbol: "ATOM",
        logoimage: ATOM,
        usdt_price: 0,
        irt_price: 0,
        suspend_trade: false
    },
    {
        name_en: "yearn.finance",
        name_fa: "یرن فایننس",
        symbol: "YFI",
        logoimage: YFI,
        usdt_price: 0,
        irt_price: 0,
        suspend_trade: false
    },
    {
        name_en: "Aave",
        name_fa: "آوه",
        symbol: "AAVE",
        logoimage: AAVE,
        usdt_price: 0,
        irt_price: 0,
        suspend_trade: false
    },
    {
        name_en: "Dash",
        name_fa: "دش",
        symbol: "DASH",
        logoimage: DASH,
        usdt_price: 0,
        irt_price: 0,
        suspend_trade: false
    },
    {
        name_en: "VeChain",
        name_fa: "وی‌چین",
        symbol: "VET",
        logoimage: VET,
        usdt_price: 0,
        irt_price: 0,
        suspend_trade: false
    },
    {
        name_en: "Ethereum Classic",
        name_fa: "اتریوم کلاسیک",
        symbol: "ETC",
        logoimage: ETC,
        usdt_price: 0,
        irt_price: 0,
        suspend_trade: false
    },
    {
        name_en: "Zcash",
        name_fa: "زی‌کش",
        symbol: "ZEC",
        logoimage: ZEC,
        usdt_price: 0,
        irt_price: 0,
        suspend_trade: false
    },
    {
        name_en: "Dogecoin",
        name_fa: "دوج‌کوین",
        symbol: "DOGE",
        logoimage: DOGE,
        usdt_price: 0,
        irt_price: 0,
        suspend_trade: false
    },
    {
        name_en: "Band Protocol",
        name_fa: "بند پروتکل",
        symbol: "BAND",
        logoimage: BAND,
        usdt_price: 0,
        irt_price: 0,
        suspend_trade: false
    },
    {
        name_en: "Uniswap",
        name_fa: "یونی‌سواپ",
        symbol: "UNI",
        logoimage: UNI,
        usdt_price: 0,
        irt_price: 0,
        suspend_trade: false
    },
    {
        name_en: "NEM",
        name_fa: "نم",
        symbol: "XEM",
        logoimage: XEM,
        usdt_price: 0,
        irt_price: 0,
        suspend_trade: false
    },
    {
        name_en: "SHIBA INU",
        name_fa: "شیبا",
        symbol: "SHIB",
        logoimage: SHIB,
        usdt_price: 0,
        irt_price: 0,
        suspend_trade: false
    },
    {
        name_en: "Bull",
        name_fa: "بیت‌کوین بول",
        symbol: "BULL",
        logoimage: BTCBULL,
        usdt_price: 0,
        irt_price: 0,
        suspend_trade: false
    },
    {
        name_en: "Bear",
        name_fa: "بیت‌کوین بیر",
        symbol: "BEAR",
        logoimage: BTCBEAR,
        usdt_price: 0,
        irt_price: 0,
        suspend_trade: false
    },
    {
        name_en: "Ethereum Bull",
        name_fa: "اتریوم بول",
        symbol: "ETHBULL",
        logoimage: ETHBULL,
        usdt_price: 0,
        irt_price: 0,
        suspend_trade: false
    },
    {
        name_en: "Ethereum Bear",
        name_fa: "اتریوم بیر",
        symbol: "ETHBEAR",
        logoimage: ETHBEAR,
        usdt_price: 0,
        irt_price: 0,
        suspend_trade: false
    },
    {
        name_en: "Eos",
        name_fa: "ایاس",
        symbol: "EOS",
        logoimage: EOS,
        usdt_price: 0,
        irt_price: 0,
        suspend_trade: false
    },
    {
        name_en: "Solana",
        name_fa: "سولانا",
        symbol: "SOL",
        logoimage: SOL,
        usdt_price: 0,
        irt_price: 0,
        suspend_trade: false
    },
    {
        name_en: "Binance USD",
        name_fa: "بایننس یو‌اس‌دی",
        symbol: "BUSD",
        logoimage: BUSD,
        usdt_price: 0,
        irt_price: 0,
        suspend_trade: false
    },
];

export const currencyTablePrimary = [
    {
        market: "IRT",
        price: 0,
        daily_change: 0,
        low_price: 0,
        high_price: 0,
        pair_symbol: "BTC/IRT",
        weekly_change: [],
        currency: {
            name_en: "Bitcoin",
            name_fa: "بیت‌کوین",
            symbol: "BTC",
            logoimage: BTC
        }
    },
    {
        market: "IRT",
        price: 0,
        daily_change: 0,
        low_price: 0,
        high_price: 0,
        pair_symbol: "ETH/IRT",
        weekly_change: [],
        currency: {
            name_en: "Ethereum",
            name_fa: "اتریوم",
            symbol: "ETH",
            logoimage: ETH
        }
    },
    {
        market: "IRT",
        price: 0,
        daily_change: 0,
        low_price: 0,
        high_price: 0,
        pair_symbol: "USDT/IRT",
        weekly_change: [],
        currency: {
            name_en: "Tether",
            name_fa: "تتر",
            symbol: "USDT",
            logoimage: USDT
        }
    },
    {
        market: "IRT",
        price: 0,
        daily_change: 0,
        low_price: 0,
        high_price: 0,
        pair_symbol: "XRP/IRT",
        weekly_change: [],
        currency: {
            name_en: "Ripple",
            name_fa: "ریپل",
            symbol: "XRP",
            logoimage: XRP
        }
    },
    {
        market: "IRT",
        price: 0,
        daily_change: 0,
        low_price: 0,
        high_price: 0,
        pair_symbol: "BNB/IRT",
        weekly_change: [],
        currency: {
            name_en: "Binance Coin",
            name_fa: "بایننس‌کوین",
            symbol: "BNB",
            logoimage: BNB
        }
    },
    {
        market: "USD",
        price: 0,
        daily_change: 0,
        low_price: 0,
        high_price: 0,
        pair_symbol: "BTC/USDT",
        weekly_change: [],
        currency: {
            name_en: "Bitcoin",
            name_fa: "بیت‌کوین",
            symbol: "BTC",
            logoimage: BTC
        }
    },
    {
        market: "USD",
        price: 0,
        daily_change: 0,
        low_price: 0,
        high_price: 0,
        pair_symbol: "ETH/USDT",
        weekly_change: [],
        currency: {
            name_en: "Ethereum",
            name_fa: "اتریوم",
            symbol: "ETH",
            logoimage: ETH
        }
    },
    {
        market: "USD",
        price: 0,
        daily_change: 0,
        low_price: 0,
        high_price: 0,
        pair_symbol: "XRP/USDT",
        weekly_change: [],
        currency: {
            name_en: "Ripple",
            name_fa: "ریپل",
            symbol: "XRP",
            logoimage: XRP
        }
    },
    {
        market: "USD",
        price: 0,
        daily_change: 0,
        low_price: 0,
        high_price: 0,
        pair_symbol: "BNB/USDT",
        weekly_change: [],
        currency: {
            name_en: "Binance Coin",
            name_fa: "بایننس‌کوین",
            symbol: "BNB",
            logoimage: BNB
        }
    },
    {
        market: "USD",
        price: 0,
        daily_change: 0,
        low_price: 0,
        high_price: 0,
        pair_symbol: "DOT/USDT",
        weekly_change: [],
        currency: {
            name_en: "Polkadot",
            name_fa: "پولکادات",
            symbol: "DOT",
            logoimage: DOT
        }
    }
];