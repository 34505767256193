
export const getCryptoList = (isLoggedIn = false) => ({
  method: 'get',
  token: isLoggedIn,
  url: 'Currency/cryptos/',
});

export const getAllCurrencyList = (isLoggedIn = false) => ({
  method: 'get',
  token: isLoggedIn,
  url: 'Currency/all/',
});

export const priceList = (isLoggedIn = false) => ({
  method: 'get',
  url: 'CurrencyPrice/Price/',
  token: isLoggedIn,
});

export const chartPriceList = (isLoggedIn = false) => ({
  method: 'get',
  url: 'CurrencyPrice/Chart_Price/',
  token: isLoggedIn,
});

export const setFavoriteCurrency = (payload) => ({
  method: 'get',
  url: 'FavoriteCurrency/SetFavorite/',
  token: true,
  params: {
    IsFavorite: payload.isFavorite,
    normal_symbol: payload.symbol,
    market_symbol: payload.marketType,
  }
});
