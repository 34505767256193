
import React, { useState } from 'react';
import classes from './UserQuickMenu.module.scss';
import { useTheme } from '@material-ui/core/styles';
import useMediaQuery from '@material-ui/core/useMediaQuery';
import Hidden from '@material-ui/core/Hidden';
import Avatar from '@material-ui/core/Avatar';
import Badge from '@material-ui/core/Badge';
import PersonOutlineIcon from '@material-ui/icons/PersonOutline';
import CreditCardIcon from '@material-ui/icons/CreditCard';
import ExitToAppIcon from '@material-ui/icons/ExitToApp';
import { Menu, MenuItem, MenuDivider } from '@szhsin/react-menu';
import classnames from 'classnames';
import { useHistory } from 'react-router-dom';
import { useSelector, useDispatch } from 'react-redux';

import RoutesPath from '../../../router/RoutesPath';
import { SecurityIcon } from '../../../icons/LandingIcons';
import Button from '../../../components/button/Button';
import UsernameFormatter from '../../../components/usernameFormatter/UsernameFormatter';
import MessageDialog from '../../../components/messageDialog/MessageDialog';
import { maxLevel } from '../../../data/StaticData';
import CallApi from '../../../functions/CallApi';
import { readNotification as readNotificationApi } from '../../../api/NotificationApi';
import { logout as logoutApi } from '../../../api/LoginApi';
import { logout } from '../../../redux/actions/UserActions';
import { readNotification } from '../../../redux/actions/NotificationActions';
import { UserIcon, BellIcon, ReferralIcon } from '../../../icons/CustomIcons';

const UserQuickMenu = () => {

    const history = useHistory();
    const materialTheme = useTheme();
    const reduxDispatch = useDispatch();

    const phoneMatch = useMediaQuery(materialTheme.breakpoints.down('xs'));
    const smallMatch = useMediaQuery(materialTheme.breakpoints.down('sm'));

    const {
        UserReducer: userData,
        NotificationReducer: { unreadNotificationList: newNotificationList }
    } = useSelector(state => state);

    const [selectedMessage, setSelectedMessage] = useState(null);
    const [isMessageDetailShow, setIsMessageDetailShow] = useState(false);

    const isLoggedIn = (userData.active && Boolean(userData.access)) ? true : false;


    // Call Api Section *************************************************************

    const readNotificationsApiCall = async (notificationId) => {
        try {
            await CallApi(readNotificationApi(notificationId));

            reduxDispatch(readNotification(notificationId, true));
        } catch (error) {
            reduxDispatch(readNotification(notificationId, false));
        };
    };

    const logoutApiCall = async () => {
        try {
            await CallApi(logoutApi(userData.refresh));
        } catch (error) {

        };
    };

    //*******************************************************************************


    const levelupButton = () => {
        history.push(RoutesPath.userLevel);
        // switch (Number(userData.level)) {
        //     case 0:
        //         history.push(RoutesPath.twoFA);
        //         break;
        //     case 1:
        //         history.push(RoutesPath.levelUpTwo);
        //         break;
        //     case 2:
        //         history.push(RoutesPath.levelUpThree);
        //         break;
        //     default:
        //         break;
        // };
    };

    const userLevelButton = () => {
        history.push(RoutesPath.userLevel);
    };

    const accountDataButton = () => {
        history.push(RoutesPath.userInfo);
    };

    const settingButton = () => {
        history.push(RoutesPath.security);
    };

    const bankCardsButton = () => {
        history.push(RoutesPath.bankAccounts);
    };

    const registerButton = () => {
        history.push(RoutesPath.register);
    };

    const loginButton = () => {
        history.push(RoutesPath.login);
    };

    const logoutButton = () => {
        logoutApiCall();
        reduxDispatch(logout());
        history.push(RoutesPath.home);
    };

    const referralButton = () => {
        history.push(RoutesPath.referral);
    };

    const allNotificationButton = () => {
        history.push(RoutesPath.notification);
    };

    const mouseEnterNotification = () => {

    };

    const mouseLeaveNotification = () => {

    };

    const messageButton = (message) => {
        readNotificationsApiCall(message.id);
        setSelectedMessage(message);
        showMessageDetailChangeHandler(true);
    };

    const showMessageDetailChangeHandler = (isShow) => {
        setIsMessageDetailShow(isShow);
    };

    return (
        <div className={classes.main}>
            <MessageDialog
                open={isMessageDetailShow}
                title='جزئیات اعلان'
                handleClose={() => showMessageDetailChangeHandler(false)}
            >
                <div className={classes.messageMainDiv}>
                    <span className='text-large'>{selectedMessage?.title}</span>

                    <span className={`text-medium ${classes.messageTimeText}`}>
                        {selectedMessage?.time[1]}  {selectedMessage?.time[0]}
                    </span>

                    <span className={`text-medium ${classes.messageDescription}`}>
                        {selectedMessage?.description}
                    </span>
                </div>
            </MessageDialog>

            {isLoggedIn ?
                <div className={classes.loggedInUserDiv}>
                    {!phoneMatch &&
                        <ReferralIcon
                            className={classes.referralIcon}
                            onClick={referralButton}
                        />
                    }

                    <Badge
                        classes={{ badge: classes.badgeRoot }}
                        overlap='circle'
                        max={99}
                        badgeContent={newNotificationList.length}
                    >
                        <Menu
                            className={classnames(
                                classes.menuNotif,
                                {
                                    'text-large': smallMatch,
                                    'text-medium': !smallMatch,
                                }
                            )}
                            menuButton={
                                <div className={classes.notificationDiv}>
                                    <BellIcon
                                        className={classes.notificationIcon}
                                        onMouseEnter={mouseEnterNotification}
                                        onMouseLeave={mouseLeaveNotification}
                                    />
                                </div>
                            }
                        >
                            <MenuItem disabled className={classes.disabledMenuItem}>
                                <div className={classes.notifTitleDiv}>
                                    <span className='text-large'>اعلانات</span>
                                    <span
                                        className={classes.allNotifButton}
                                        onClick={allNotificationButton}
                                    >
                                        نمایش همه
                                    </span>
                                </div>
                            </MenuItem>

                            <MenuDivider className={classes.menuDivider} />

                            {newNotificationList.length > 0 ?
                                newNotificationList.map((item) =>
                                    <MenuItem
                                        key={item.id}
                                        className={classes.menuItemNotif}
                                        onClick={() => messageButton(item)}
                                    >
                                        <div className={classes.notifMessageDiv}>
                                            <div className={classes.notifTitle}>
                                                <span className={classes.unreadCircle} />
                                                <span className='text-medium'>{item.title}</span>
                                            </div>

                                            <span className={`text-medium ${classes.dateText}`}>
                                                {item.time[0]}
                                            </span>
                                        </div>
                                    </MenuItem>
                                )
                                :
                                <MenuItem disabled>
                                    <span className={classes.emptyMessageText}>پیام جدیدی وجود ندارد</span>
                                </MenuItem>
                            }
                        </Menu>
                    </Badge>

                    <Hidden xsDown>
                        <Button
                            className={classes.levelupButton}
                            onClick={levelupButton}
                        >
                            {Number(userData.level) === maxLevel ?
                                <span className={smallMatch ? 'text-medium' : 'text-large'}>سطح {maxLevel}</span>
                                :
                                <span className={smallMatch ? 'text-medium' : 'text-large'}>ارتقا به سطح {Number(userData.level) + 1}</span>
                            }
                        </Button>
                    </Hidden>

                    <Menu
                        className={classnames(
                            classes.menu,
                            {
                                'text-large': smallMatch,
                                'text-medium': !smallMatch,
                            }
                        )}
                        menuButton={
                            <div className={classes.profileDiv}>
                                {true ?
                                    <UserIcon className={classes.userIcon} />
                                    :
                                    <Avatar alt="user" src='' className={classes.userAvatar} />
                                }
                            </div>
                        }
                    >
                        <MenuItem className={`${classes.menuItem} ${classes.levelMenuItem}`} onClick={userLevelButton}>
                            <div className={classes.userLevelDiv}>
                                <UsernameFormatter username={userData?.username} />
                                <span>کاربر سطح {userData.level}</span>
                            </div>
                        </MenuItem>

                        <MenuItem disabled className={classes.separatorMenuItem}>
                            <div className={classes.separator} />
                        </MenuItem>

                        <MenuItem className={classes.menuItem} onClick={accountDataButton}>
                            <PersonOutlineIcon id={classes.userMenuItemIcon} />
                            حساب کاربری
                        </MenuItem>

                        <MenuItem className={classes.menuItem} onClick={settingButton}>
                            <SecurityIcon className={classes.securityMenuItemIcon} />
                            امنیت
                        </MenuItem>

                        <MenuItem className={classes.menuItem} onClick={bankCardsButton}>
                            <CreditCardIcon id={classes.menuItemIcon} />
                            حساب‌های بانکی
                        </MenuItem>

                        <MenuItem className={classes.menuItem} onClick={logoutButton}>
                            <ExitToAppIcon id={classes.menuItemIcon} />
                            خروج
                        </MenuItem>
                    </Menu>
                </div>
                :
                <div className={`text-medium text-bold ${classes.buttonsDiv}`}>
                    <span
                        className={classes.loginButton}
                        onClick={loginButton}
                    >
                        ورود
                    </span>

                    <Button
                        className={classes.registerButton}
                        onClick={registerButton}
                    >
                        <span className='text-large text-bold'>ثبت نام</span>
                    </Button>
                </div>
            }
        </div>
    );
};

export default UserQuickMenu;
