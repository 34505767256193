
import { useSelector } from 'react-redux';

const useLogin = () => {

    const userData = useSelector(state => state.UserReducer);

    let isLoggedIn = false;
    if (Boolean(userData.refresh) && Boolean(userData.access) && userData.active) {
        isLoggedIn = true;
    };

    return isLoggedIn;
};

export default useLogin;
