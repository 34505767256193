
import { lazy } from 'react';

import RoutesPath from './RoutesPath';

const Home = lazy(() => import('../home/Home'));
const Notification = lazy(() => import('../home/Notification'));
const Error404 = lazy(() => import('../home/Error404'));
const Referral = lazy(() => import('../home/Referral'));
const AboutUs = lazy(() => import('../home/AboutUs'));
const Terms = lazy(() => import('../home/Terms'));
const Login = lazy(() => import('../authentication/login/Login'));
const LoginAuthenticator = lazy(() => import('../authentication/login/LoginAuthenticator'));
const Register = lazy(() => import('../authentication/register/Register'));
const ActiveAccount = lazy(() => import('../authentication/register/ActiveAccount'));
const ForgetPassword = lazy(() => import('../authentication/ForgetPassword'));
const SendCodeForgetPassword = lazy(() => import('../authentication/forgetPassword/SendCodeForgetPassword'));
const ActiveCodeForgetPassword = lazy(() => import('../authentication/forgetPassword/ActiveCodeForgetPassword'));
const NewPasswordForgetPassword = lazy(() => import('../authentication/forgetPassword/NewPasswordForgetPassword'));
const NewDevice = lazy(() => import('../authentication/newDevice/NewDevice'));
const Trade = lazy(() => import('../trade/Trade'));
const MobileAppChart = lazy(() => import('../trade/mobileAppChart/MobileAppChart'));
const Bank = lazy(() => import('../containers/bank/Bank'));
const BankAccounts = lazy(() => import('../containers/bank/BankAccounts'));
const PayWaiting = lazy(() => import('../containers/bank/PayWaiting'));
const PaySuccess = lazy(() => import('../containers/bank/PaySuccess'));
const PayFailed = lazy(() => import('../containers/bank/PayFailed'));
const Security = lazy(() => import('../containers/security/Security'));
const ChangePassword = lazy(() => import('../containers/security/ChangePassword'));
const TwoFA = lazy(() => import('../containers/security/TwoFA'));
const MobileTwoFA = lazy(() => import('../containers/security/twoFA/MobileTwoFA'));
const GoogleAuthenticatorTwoFA = lazy(() => import('../containers/security/twoFA/GoogleAuthenticatorTwoFA'));
const DisableTwoFA = lazy(() => import('../containers/security/twoFA/DisableTwoFA'));
const Profile = lazy(() => import('../containers/profile/Profile'));
const UserInfo = lazy(() => import('../containers/profile/UserInfo'));
const UserLevel = lazy(() => import('../containers/profile/UserLevel'));
const LevelUpTwo = lazy(() => import('../containers/profile/levelups/LevelUpTwo'));
const LevelUpThree = lazy(() => import('../containers/profile/levelups/LevelUpThree'));
const Dashboard = lazy(() => import('../containers/dashboard/Dashboard'));
const Convert = lazy(() => import('../containers/convert/Convert'));
const Market = lazy(() => import('../containers/market/Market'));
const Wallet = lazy(() => import('../containers/wallet/Wallet'));
const FinancialAssets = lazy(() => import('../containers/wallet/walletTabs/FinancialAssets'));
const Deposit = lazy(() => import('../containers/wallet/walletTabs/Deposit'));
const Withdraw = lazy(() => import('../containers/wallet/walletTabs/Withdraw'));
const TransactionHistory = lazy(() => import('../containers/wallet/walletTabs/TransactionHistory'));
const Order = lazy(() => import('../containers/order/Order'));
const Help = lazy(() => import('../containers/help/Help'));


const Routes = [
    {
        path: RoutesPath.home,
        component: Home,
        exact: true,
    },
    {
        path: RoutesPath.aboutUs,
        component: AboutUs,
    },
    {
        path: RoutesPath.terms,
        component: Terms,
    },
    {
        path: RoutesPath.notification,
        component: Notification,
        menu: true,
        private: true,
        title: 'اعلانات',
    },
    {
        path: RoutesPath.referral,
        component: Referral,
        menu: true,
        private: true,
        title: 'دعوت از دوستان',
    },
    {
        path: RoutesPath.register + '/:inviteFriendCode?',
        component: Register,
    },
    {
        path: RoutesPath.activeAccount,
        component: ActiveAccount,
    },
    {
        path: RoutesPath.login,
        component: Login,
        exact: true,
    },
    {
        path: RoutesPath.loginAuthenticator,
        component: LoginAuthenticator,
    },
    {
        path: RoutesPath.forgetPassword,
        component: ForgetPassword,
        routes: [
            {
                path: RoutesPath.sendCodeForgetPassword,
                component: SendCodeForgetPassword,
            },
            {
                path: RoutesPath.activeCodeForgetPassword,
                component: ActiveCodeForgetPassword,
            },
            {
                path: RoutesPath.newPasswordForgetPassword,
                component: NewPasswordForgetPassword,
            },
        ],
    },
    {
        path: RoutesPath.newDevice,
        component: NewDevice,
    },
    {
        path: RoutesPath.dashboard,
        component: Dashboard,
        menu: true,
        private: true,
        title: 'داشبورد',
    },
    {
        path: RoutesPath.trade + '/:tradeMarket',
        component: Trade,
        menu: true,
    },
    {
        path: RoutesPath.mobileAppChart + '/:tradeMarket',
        component: MobileAppChart,
    },
    {
        path: RoutesPath.convert,
        component: Convert,
        menu: true,
        title: 'تبدیل',
    },
    {
        path: RoutesPath.market,
        component: Market,
        menu: true,
        title: 'مارکت',
    },
    {
        path: RoutesPath.wallet,
        component: Wallet,
        menu: true,
        private: true,
        title: 'کیف پول',
        hasTab: true,
        routes: [
            {
                path: RoutesPath.financialAssets,
                component: FinancialAssets,
                title: 'دارایی‌ها',
            },
            {
                path: RoutesPath.deposit,
                component: Deposit,
                title: 'واریز',
            },
            {
                path: RoutesPath.withdraw,
                component: Withdraw,
                title: 'برداشت',
            },
            {
                path: RoutesPath.transactionHistory,
                component: TransactionHistory,
                title: 'تاریخچه',
            },
        ],
    },
    {
        path: RoutesPath.order,
        component: Order,
        menu: true,
        private: true,
    },
    {
        path: RoutesPath.help,
        component: Help,
        menu: true,
    },
    {
        path: RoutesPath.bank,
        component: Bank,
        menu: true,
        private: true,
        hasTab: true,
        title: 'بانک',
        routes: [
            {
                path: RoutesPath.bankAccounts,
                component: BankAccounts,
                title: 'حساب‌های بانکی',
            },
        ],
    },
    {
        path: RoutesPath.profile,
        component: Profile,
        menu: true,
        private: true,
        hasTab: true,
        title: 'پروفایل',
        routes: [
            {
                path: RoutesPath.userLevel,
                component: UserLevel,
                title: 'سطح کاربری',
                routes: [
                    {
                        path: RoutesPath.levelUpTwo,
                        component: LevelUpTwo,
                    },
                    {
                        path: RoutesPath.levelUpThree,
                        component: LevelUpThree,
                    },
                ],
            },
            {
                path: RoutesPath.userInfo,
                component: UserInfo,
                title: 'اطلاعات کاربری',
            },
        ],
    },
    {
        path: RoutesPath.security,
        component: Security,
        menu: true,
        private: true,
        hasTab: true,
        title: 'امنیت',
        routes: [
            {
                path: RoutesPath.twoFA,
                component: TwoFA,
                title: 'شناسایی دو عاملی (2FA)',
                routes: [
                    {
                        path: RoutesPath.mobileTowFA,
                        component: MobileTwoFA,
                    },
                    {
                        path: RoutesPath.googleAuthenticatorTowFA,
                        component: GoogleAuthenticatorTwoFA,
                    },
                    {
                        path: RoutesPath.disableTowFA,
                        component: DisableTwoFA,
                    },
                ]
            },
            {
                path: RoutesPath.changePassword,
                component: ChangePassword,
                title: 'تغییر رمز عبور',
            },
        ],
    },
    {
        path: RoutesPath.paymentWaiting,
        component: PayWaiting,
    },
    {
        path: RoutesPath.paymentSuccess,
        component: PaySuccess,
    },
    {
        path: RoutesPath.paymentFailed,
        component: PayFailed,
    },
    {
        path: '*',
        component: Error404,
    },
];

export default Routes;