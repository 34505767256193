
import ThemeTypes from '../types/ThemeTypes';

const initialState = 'dark';

const ThemeReducer = (state = initialState, action) => {
    switch (action.type) {
        case ThemeTypes.changeTheme: {
            let theme = (state === 'dark') ? 'light' : 'dark';
            return theme;
        };

        default: return state;
    };
};

export default ThemeReducer;