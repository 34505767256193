
export const createPrv = (twoFAType) => ({
  method: 'get',
  url: 'TwoFA/CreatePrv/',
  token: true,
  params: {
    twofa_type: twoFAType,
  }
});

export const sendEnableCode = () => ({
  method: 'get',
  url: 'TwoFA/SendEmailOrSmsForEnable/',
  token: true,
});

export const sendTwoFASmsCode = () => ({
  method: 'get',
  url: 'TwoFA/SendSmsCodeForTwofa/',
  token: true,
});

export const sendDisableCode = () => ({
  method: 'get',
  url: 'TwoFA/SendEmailOTPCodeDisable/',
  token: true,
});

export const sendTwoFAMobileNumber = (mobile_number) => ({
  method: 'post',
  url: 'TwoFA/ReciveTwoFAMobileNumber/',
  token: true,
  data: {
    mobile_number,
  },
});

export const sendNewDeviceCode = () => ({
  method: 'get',
  url: 'TwoFA/SendEmailOrSmsCodeForNewDevice/',
  token: true,
});

export const enableTwoFA = (payload) => ({
  method: 'post',
  url: 'TwoFA/EnableTwoFA/',
  token: true,
  data: {
    user_otp_code: payload.otpCode,
    user_twofa_otp_code: payload.twoFACode,
  },
});

export const disableTwoFA = (payload) => ({
  method: 'post',
  url: 'TwoFA/DisableTwoFA/',
  token: true,
  data: {
    user_otp_code: payload.otpCode,
    user_twofa_otp_code: payload.twoFACode,
  },
});

export const validateOTP = (user_twofa_otp_code) => ({
  method: 'post',
  url: 'TwoFA/ValidateTwoFAOTP/',
  token: true,
  data: {
    user_twofa_otp_code,
  },
});

export const validateUser = (payload) => ({
  method: 'post',
  url: 'TwoFA/ValidateTwoFAOTP/',
  token: true,
  data: {
    user_otp_code: payload.otpCode,
    user_twofa_otp_code: payload.twoFACode,
  },
});
