
import React, { Component } from 'react';

import { setReloadExpiry, getReloadExpiry } from '../functions/ChunkReloadTime';

class ErrorBoundary extends Component {

    constructor(props) {
        super(props);
        this.state = {
            hasError: false
        };
    };

    static getDerivedStateFromError() {
        return { hasError: true };
    };

    componentDidCatch(error) {
        const chunkFailedMessage = /Loading chunk [\d]+ failed/;

        if (error?.message && chunkFailedMessage.test(error.message)) {
            if (!getReloadExpiry()) {
                setReloadExpiry();
                window.location.reload();
            };
        };
    };

    render() {
        if (this.state.hasError) {
            return (
                <span />
            );
        };

        return this.props.children;
    };
};

export default ErrorBoundary;