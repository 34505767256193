
import React from 'react';
import classes from './MessageDialog.module.scss';
import Dialog from '@material-ui/core/Dialog';
import CloseIcon from '@material-ui/icons/Close';
import { useSelector } from 'react-redux';

import RTL from '../RTL';
import Button from '../button/Button';

const MessageDialog = ({ open, title = '', children, handleClose }) => {

    const theme = useSelector(state => state.ThemeReducer);

    return (
        <RTL>
            <Dialog
                classes={{ paper: classes.dialog }}
                aria-labelledby='rules dialog'
                BackdropProps={{ style: { backgroundColor: '#000', opacity: 0.8 } }}
                fullWidth
                maxWidth='xs'
                scroll='body'
                open={open}
                onClose={handleClose}
            >
                <div className={`${theme} ${classes.mainDiv}`}>
                    <div className={classes.titleDiv}>
                        <span className='text-large'>{title}</span>

                        <div className={classes.iconDiv} onClick={handleClose}>
                            <CloseIcon id={classes.cancelIcon} />
                        </div>
                    </div>

                    {children}

                    <Button
                        className={classes.closeButton}
                        onClick={handleClose}
                    >
                        <span className='text-large text-bold'>متوجه شدم</span>
                    </Button>
                </div>
            </Dialog>
        </RTL>
    );
};

export default MessageDialog;