
import React from 'react';

const LogoWithoutName = ({ className, onClick }) => {
    return (
        <svg className={className} onClick={onClick} id="new_logo" data-name="new logo" xmlns="http://www.w3.org/2000/svg" width="26.127" height="30.173" viewBox="0 0 26.127 30.173">
            <path id="Path_28638" data-name="Path 28638" d="M0,113.34l3.281,1.917v11.3L0,128.427Z" transform="translate(0 -105.799)" fill="#ff8f20" />
            <path id="Path_28639" data-name="Path 28639" d="M346.661,113.38,343.38,115.3v11.3l3.281,1.894Z" transform="translate(-320.533 -105.836)" fill="#ff8f20" />
            <path id="Path_28640" data-name="Path 28640" d="M29.66,330.863l3.261-1.883,7.828,4.519,7.828-4.519,3.262,1.883-11.089,6.4Z" transform="translate(-27.687 -307.092)" fill="#ff8f20" />
            <path id="Path_28641" data-name="Path 28641" d="M29.07,6.425l3.3,1.906L40.2,3.766,48.026,8.33l3.305-1.9L40.2,0Z" transform="translate(-27.136)" fill="#ff8f20" />
            <path id="Path_28642" data-name="Path 28642" d="M78.67,168.208l7.828,4.52,7.828-4.52V159.17l-3.262,1.883v5.272L86.5,168.961l-4.566-2.636v-5.272L78.67,159.17Z" transform="translate(-73.436 -148.58)" fill="#ff8f20" />
        </svg>
    );
};

export { LogoWithoutName };
