
import AnnouncementTypes from '../types/AnnouncementTypes';

const initialState = [];

const AnnouncementReducer = (state = initialState, action) => {
    switch (action.type) {
        case AnnouncementTypes.setAnnouncementList: {
            let announcementListNew = action?.announcementList || [];

            return announcementListNew;
        };

        case AnnouncementTypes.hideAnnouncement: {
            let announcementListNew = state.filter((item) => item?.id !== action?.announcementId);

            return announcementListNew;
        };

        default:
            return state;
    };
};

export default AnnouncementReducer;