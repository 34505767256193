
import CryptoTypes from '../types/CryptoTypes';

export const setCurrencyList = (payload) => {
    return {
        type: CryptoTypes.setCryptoList,
        payload,
    };
};

export const errorCrypto = (error) => {
    return {
        type: CryptoTypes.errorCrypto,
        error,
    };
};
