
import React, { useState, useEffect } from 'react';
import classes from './TwoFADialog.module.scss';
import Dialog from '@material-ui/core/Dialog';
import ScheduleIcon from '@material-ui/icons/Schedule';
import classnames from 'classnames';
import * as yup from 'yup';
import { useSnackbar } from 'notistack';
import { useHistory } from 'react-router-dom';
import { useSelector, useDispatch } from 'react-redux';

import useTimer from '../../hooks/useTimer';
import RoutesPath from '../../router/RoutesPath';
import RTL from '../RTL';
import Input from '../input/Input';
import Button from '../button/Button';
import ErrorMessage from '../errorMessage/ErrorMessage';
import { twoFATypes } from '../../data/StaticData';
import ErrorResponseCode, { defaultErrorMessage } from '../../data/ErrorResponseCode';
import { showDialogChange } from '../../redux/actions/TwoFAActions';
import CallApi from '../../functions/CallApi';
import { sendTwoFASmsCode, validateOTP } from '../../api/TwoFAApi';
import { logout as logoutApi } from '../../api/LoginApi';
import { logout } from '../../redux/actions/UserActions';

const TwoFADialog = ({ open }) => {

    const { enqueueSnackbar } = useSnackbar();
    const history = useHistory();
    const reduxDispatch = useDispatch();

    const theme = useSelector(state => state.ThemeReducer);
    const { refresh, twoFAType, mobile } = useSelector(state => state.UserReducer);

    const [twoFACode, setTwoFACode] = useState('');
    const [timerStart, setTimerStart] = useState(false);
    const [codeLabel, setCodeLabel] = useState('ارسال کد');
    const [sendCodeDisabled, setSendCodeDisabled] = useState(false);
    const [confirmDisabled, setConfirmDisabled] = useState(true);
    const [credentialError, setCredentialError] = useState(null);
    const [isLoading, setIsLoading] = useState(false);

    let { minutes, seconds, finishTimer } = useTimer(180, timerStart);

    useEffect(() => {
        if (finishTimer) setTimerStart(false);
    }, [finishTimer]);

    useEffect(() => {
        const checkFormValidation = async () => {
            let isFormValidate = await formValidation();

            setConfirmDisabled(!isFormValidate);

            if (isFormValidate) validateOTPApiCall();
        };

        checkFormValidation();
    }, [twoFACode]);


    // Call Api Section *************************************************************

    const sendTwoFASmsCodeApiCall = async () => {
        try {
            let response = await CallApi(sendTwoFASmsCode());

            let showMessage = response?.message || '';
            enqueueSnackbar(showMessage, { variant: 'success' });

            setTimerStart(true);
            setCodeLabel('ارسال مجدد');

        } catch (error) {
            let responseStatus = error?.response?.status;
            let responseData = error?.response?.data;
            let showMessage = responseData?.message || defaultErrorMessage.serverError;

            enqueueSnackbar(showMessage, { variant: 'error' });

        } finally {
            setSendCodeDisabled(false);
        };
    };

    const validateOTPApiCall = async () => {
        setIsLoading(true);

        try {
            let response = await CallApi(validateOTP(twoFACode));

            let showMessage = response?.message || '';
            enqueueSnackbar(showMessage, { variant: 'success' });

            OTPValidated();

        } catch (error) {
            let responseStatus = error?.response?.status;
            let responseData = error?.response?.data;
            let showMessage = responseData?.message || defaultErrorMessage.serverError;

            if (responseStatus === ErrorResponseCode.badRequest) {
                setCredentialError(showMessage);
            } else {
                enqueueSnackbar(showMessage, { variant: 'error' });
            };

        } finally {
            setIsLoading(false);
        };
    };

    const logoutApiCall = async () => {
        try {
            await CallApi(logoutApi(refresh));
        } catch (error) {

        };
    };

    //*******************************************************************************


    const twoFACodeChangeHandler = (event) => {
        setTwoFACode(event.target.value);
    };

    const formValidation = async () => {
        let schema = yup.string().required().length(6);

        try {
            await schema.validate(twoFACode);
            return true;
        } catch (error) {
            return false;
        };
    };

    const handleClose = () => {
        setTwoFACode('');
        setCredentialError(null);
        reduxDispatch(showDialogChange(false));
    };

    const sendCodeButton = () => {
        setSendCodeDisabled(true);
        sendTwoFASmsCodeApiCall();
    };

    const confirmButton = (event) => {
        event.preventDefault();
        validateOTPApiCall();
    };

    const logoutButton = (event) => {
        event.preventDefault();

        logoutApiCall();
        reduxDispatch(logout());
        handleClose();
        history.push(RoutesPath.login);
    };

    const OTPValidated = () => {
        handleClose();
        window.location.reload();
    };

    return (
        <RTL>
            <Dialog
                classes={{ paper: classes.dialog }}
                aria-labelledby='2fa validation dialog'
                BackdropProps={{ style: { backgroundColor: '#000', opacity: 0.8 } }}
                fullWidth
                maxWidth='xs'
                disableBackdropClick
                disableEscapeKeyDown
                open={open}
                onClose={handleClose}
            >
                <form className={`${theme} ${classes.form}`}>
                    <span className='text-large'>شناسایی دو عاملی</span>

                    <ErrorMessage message={credentialError} className={classes.errorMessage} />

                    {twoFAType === twoFATypes.google ?
                        <span className={`text-medium ${classes.descriptionText}`}>
                            مدت زمان اعتبار کد شناسایی دو عاملی شما به اتمام رسیده است. برای ادامه درخواست خود کد جدید Google Authenticator را وارد نمایید.
                        </span>
                        :
                        <span className={`text-medium ${classes.descriptionText}`}>
                            مدت زمان اعتبار کد شناسایی دو عاملی شما به اتمام رسیده است. برای ادامه درخواست لطفا دکمه ارسال کد را زده و کد ارسال شده به شماره همراه {mobile} را وارد نمایید.
                        </span>
                    }

                    <div className={classes.rowDiv}>
                        <Input
                            className={classes.twoFACodeClass}
                            inputClassName={classes.twoFACodeInput}
                            maxLength={6}
                            value={twoFACode}
                            onChange={twoFACodeChangeHandler}
                        />

                        {twoFAType === twoFATypes.mobile &&
                            <Button
                                className={classes.sendCodeButton}
                                type='button'
                                disabled={timerStart || sendCodeDisabled}
                                onClick={sendCodeButton}
                            >
                                {timerStart ?
                                    <div className={classes.timerDiv}>
                                        <ScheduleIcon id={classes.timerIcon} />
                                        <span className={classnames('text-large', classes.timerSpan)}>{minutes}:{seconds}</span>
                                    </div>
                                    :
                                    <span className='text-large'>{codeLabel}</span>
                                }
                            </Button>
                        }
                    </div>

                    <div className={classes.buttonsDiv}>
                        <Button
                            className={classes.logoutButton}
                            type='button'
                            onClick={logoutButton}
                        >
                            <span className='text-large'>خروج</span>
                        </Button>

                        <Button
                            className={classes.confirmButton}
                            type='submit'
                            loading={isLoading}
                            disabled={confirmDisabled || isLoading}
                            onClick={confirmButton}
                        >
                            <span className='text-large'>تایید</span>
                        </Button>
                    </div>
                </form>
            </Dialog>
        </RTL>
    );
};

export default TwoFADialog;
