
import CurrencyTimerTypes from '../types/CurrencyTimerTypes';

export const resetTime = () => {
    return {
        type: CurrencyTimerTypes.resetTime,
    };
};

export const updateTime = () => {
    return {
        type: CurrencyTimerTypes.updateTime,
    };
};
