
import React from 'react';

const HighCashIcon = ({ color, className }) => {
    return (
        <svg className={className} id="coins" xmlns="http://www.w3.org/2000/svg" width="24.665" height="24.665" viewBox="0 0 24.665 24.665">
            <g id="Group_3174" data-name="Group 3174">
                <g id="Group_3173" data-name="Group 3173">
                    <path id="Path_1488" data-name="Path 1488" d="M192,88.247c0,1.843,3.666,2.808,7.287,2.808s7.287-.965,7.287-2.808C206.575,84.561,192,84.561,192,88.247Z" transform="translate(-181.91 -80.99)" fill={color} />
                    <path id="Path_1489" data-name="Path 1489" d="M206.3,188.38a.562.562,0,0,0-.55-.017,17.636,17.636,0,0,1-12.933,0,.561.561,0,0,0-.821.5v.169c0,1.843,3.666,2.808,7.287,2.808s7.287-.965,7.287-2.808v-.169A.561.561,0,0,0,206.3,188.38Z" transform="translate(-181.91 -178.404)" fill={color} />
                    <path id="Path_1490" data-name="Path 1490" d="M64,301.952c0,1.843,3.666,2.808,7.287,2.808s7.287-.965,7.287-2.808C78.575,298.266,64,298.266,64,301.952Z" transform="translate(-60.637 -283.465)" fill={color} />
                    <path id="Path_1491" data-name="Path 1491" d="M78.3,402.089a.563.563,0,0,0-.55-.017,14.557,14.557,0,0,1-6.467,1.228,14.557,14.557,0,0,1-6.467-1.228.561.561,0,0,0-.821.5v.169c0,1.843,3.666,2.808,7.287,2.808s7.287-.965,7.287-2.808v-.169A.561.561,0,0,0,78.3,402.089Z" transform="translate(-60.637 -380.882)" fill={color} />
                    <path id="Path_1492" data-name="Path 1492" d="M7.287,5.572a19.7,19.7,0,0,0,2.25-.128.561.561,0,0,0,.32-.149,8.138,8.138,0,0,1,4.195-1.708.561.561,0,0,0,.436-.365,1.346,1.346,0,0,0,.086-.459C14.575-.922,0-.922,0,2.764,0,4.608,3.666,5.572,7.287,5.572Z" transform="translate(0 0)" fill={color} />
                    <path id="Path_1493" data-name="Path 1493" d="M7.287,106.354c.464,0,.917-.016,1.355-.045a.562.562,0,0,0,.488-.756,2.482,2.482,0,0,1-.159-.914.561.561,0,0,0-.174-.406.538.538,0,0,0-.413-.155c-.366.018-.732.03-1.1.03A14.557,14.557,0,0,1,.821,102.88a.561.561,0,0,0-.821.5v.169C0,105.39,3.666,106.354,7.287,106.354Z" transform="translate(0 -97.413)" fill={color} />
                    <path id="Path_1494" data-name="Path 1494" d="M7.287,170.466c.464,0,.917-.016,1.355-.045a.562.562,0,0,0,.488-.756,2.541,2.541,0,0,1-.162-.914.561.561,0,0,0-.174-.406.549.549,0,0,0-.413-.155c-.365.018-.73.03-1.094.03a14.557,14.557,0,0,1-6.467-1.228.561.561,0,0,0-.821.5v.169C0,169.5,3.666,170.466,7.287,170.466Z" transform="translate(0 -158.156)" fill={color} />
                    <path id="Path_1495" data-name="Path 1495" d="M364.924,316.593a.558.558,0,0,0-.55-.017,12.128,12.128,0,0,1-4.291,1.117.647.647,0,0,0-.494.673,2.513,2.513,0,0,1-.14.83.561.561,0,0,0,.53.745.553.553,0,0,0,.06,0c3.228-.349,5.156-1.356,5.156-2.695v-.169A.561.561,0,0,0,364.924,316.593Z" transform="translate(-340.53 -299.878)" fill={color} />
                    <path id="Path_1496" data-name="Path 1496" d="M192.562,253.693l.372.007h.01a.562.562,0,0,0,.232-1.073l-.12-.054c-.08-.035-.16-.07-.235-.109a.561.561,0,0,0-.819.5v.169A.561.561,0,0,0,192.562,253.693Z" transform="translate(-181.911 -239.136)" fill={color} />
                    <path id="Path_1497" data-name="Path 1497" d="M320.778,252.492a.562.562,0,0,0-.55-.017,14.557,14.557,0,0,1-6.467,1.228l-.37-.007a.576.576,0,0,0-.554.439.561.561,0,0,0,.315.633,4.987,4.987,0,0,1,1.474.976.561.561,0,0,0,.4.164h.036c2.763-.175,5.991-1.005,5.991-2.766v-.169A.561.561,0,0,0,320.778,252.492Z" transform="translate(-296.384 -239.146)" fill={color} />
                    <path id="Path_1498" data-name="Path 1498" d="M4.688,234.4a.558.558,0,0,0,.206-.04,13.488,13.488,0,0,1,3.58-.8.679.679,0,0,0,.494-.7.562.562,0,0,0-.587-.561c-.365.018-.73.03-1.094.03A14.557,14.557,0,0,1,.821,231.1a.56.56,0,0,0-.821.5v.169C0,233.012,1.723,234,4.61,234.4A.531.531,0,0,0,4.688,234.4Z" transform="translate(0 -218.899)" fill={color} />
                </g>
            </g>
        </svg>
    );
};

const LowCommissionIcon = ({ color, className }) => {
    return (
        <svg className={className} id="save-money" xmlns="http://www.w3.org/2000/svg" width="24.605" height="29.971" viewBox="0 0 24.605 29.971">
            <path id="Path_1499" data-name="Path 1499" d="M280.521,150.189c-.253,0-.507.23-.507,1s.253,1,.507,1,.507-.23.507-1S280.774,150.189,280.521,150.189Z" transform="translate(-266.306 -141.397)" fill={color} />
            <path id="Path_1500" data-name="Path 1500" d="M180.081,86.254c0-.772-.253-1-.507-1s-.507.23-.507,1,.253,1,.507,1S180.081,87.026,180.081,86.254Z" transform="translate(-171.268 -80.262)" fill={color} />
            <path id="Path_1501" data-name="Path 1501" d="M82.235,14.271h9.822l2.855-3.212a4.239,4.239,0,0,1,1.557-1.1c.014-.208.022-.417.022-.628a9.329,9.329,0,1,0-16.9,5.445A7.147,7.147,0,0,1,82.235,14.271Zm-.112-8.279a2.145,2.145,0,1,1,4.285,0,2.145,2.145,0,1,1-4.285,0Zm1.371,5.932L89,3.862h1.947l-5.506,8.063Zm6.681.115a2.06,2.06,0,0,1-2.142-2.246,2.145,2.145,0,1,1,4.285,0A2.06,2.06,0,0,1,90.175,12.04Z" transform="translate(-75.96)" fill={color} />
            <path id="Path_1502" data-name="Path 1502" d="M83.666,195.2h0a2.484,2.484,0,0,0-3.447.258l-3.055,3.437a3.152,3.152,0,0,1,.554,1.788,3.2,3.2,0,0,1-3.212,3.188H69.839v-1.756h4.667a1.43,1.43,0,0,0,.153-2.855H66.23a5.4,5.4,0,0,0-5.4,5.4v3.3H70v-1.229h7.466L84,198.672A2.484,2.484,0,0,0,83.666,195.2Z" transform="translate(-59.955 -183.23)" fill={color} />
            <rect id="Rectangle_3598" data-name="Rectangle 3598" width="10.92" height="3.493" transform="translate(0 26.478)" fill={color} />
        </svg>
    );
};

const SecurityIcon = ({ color, className }) => {
    return (
        <svg className={className} id="shield_1_" data-name="shield(1)" xmlns="http://www.w3.org/2000/svg" width="24.584" height="26.521" viewBox="0 0 24.584 26.521">
            <path id="Path_1174" data-name="Path 1174" d="M33.176,4.028,23.74.209a2.858,2.858,0,0,0-2.144,0L12.16,4.028a2.858,2.858,0,0,0-1.767,2.958c.9,8.293,6.514,19.536,12.275,19.536S34.042,15.279,34.943,6.985A2.855,2.855,0,0,0,33.176,4.028Zm-1.251,4.35c-.621,6.318-4.519,15.2-9.257,15.2s-8.635-8.881-9.257-15.2a2.153,2.153,0,0,1,1.373-2.222L21.9,3.437a2.153,2.153,0,0,1,1.537,0l7.115,2.719A2.151,2.151,0,0,1,31.924,8.378Z" transform="translate(-10.376 0)" fill={color} />
            <path id="Path_1175" data-name="Path 1175" d="M62.959,58.031a.286.286,0,0,0-.181.3,25.931,25.931,0,0,0,2.865,9.256c1.457,2.666,3.151,4.258,4.533,4.258h0V55.293a.284.284,0,0,0-.1.019Z" transform="translate(-57.884 -50.131)" fill={color} />
        </svg>
    );
};

const SpeedIcon = ({ color, className }) => {
    return (
        <svg className={className} xmlns="http://www.w3.org/2000/svg" width="22.507" height="22.506" viewBox="0 0 22.507 22.506">
            <g id="rocket" transform="translate(0 -0.001)">
                <path id="Path_28576" data-name="Path 28576" d="M22.123,0A17.429,17.429,0,0,0,9.548,5.783,11.9,11.9,0,0,0,1.393,9.256a.469.469,0,0,0,.263.8l3.614.518-.446.5a.469.469,0,0,0,.019.644l6.027,6.027a.47.47,0,0,0,.644.018l.5-.446.518,3.614a.467.467,0,0,0,.332.362.431.431,0,0,0,.134.021.53.53,0,0,0,.363-.151A11.875,11.875,0,0,0,16.8,13.035,17.343,17.343,0,0,0,22.582.461.471.471,0,0,0,22.123,0ZM17.8,8.1a2.345,2.345,0,1,1,0-3.316A2.337,2.337,0,0,1,17.8,8.1Z" transform="translate(-0.078 0)" fill={color} />
                <path id="Path_28577" data-name="Path 28577" d="M2.555,16.849c-1,1-2.381,5.537-2.535,6.05a.469.469,0,0,0,.449.6.48.48,0,0,0,.135-.02c.513-.154,5.046-1.531,6.05-2.535a2.9,2.9,0,1,0-4.1-4.1Z" transform="translate(0 -0.996)" fill={color} />
            </g>
        </svg>
    );
};

const OrderDiversityIcon = ({ color, className }) => {
    return (
        <svg className={className} xmlns="http://www.w3.org/2000/svg" width="25.002" height="25.002" viewBox="0 0 25.002 25.002">
            <path id="order_types" data-name="order types" d="M-462.033,23424h-22.938a1.129,1.129,0,0,1-1.029-1.205v-22.586a1.132,1.132,0,0,1,1.029-1.211h22.938a1.134,1.134,0,0,1,1.035,1.211v22.586A1.131,1.131,0,0,1-462.033,23424Zm-17.789-5.908h0v2h16v-2Zm4.415-9.936-9.9,9.9,1.417,1.416,8.495-8.494,3.257,3.258,1.411-1.412,8.906-8.912-1.417-1.41-8.906,8.906-3.251-3.252-.006.006-.006-.006Zm-8.413-5.855h0v2h15v-2Z" transform="translate(486 -23398.998)" fill={color} />
        </svg>
    );
};

const VarietyCryptoIcon = ({ color, className }) => {
    return (
        <svg className={className} id="cryptocurrency_1_" data-name="cryptocurrency(1)" xmlns="http://www.w3.org/2000/svg" width="30.556" height="26.209" viewBox="0 0 30.556 26.209">
            <path id="Path_1521" data-name="Path 1521" d="M150.522,192.108a5.478,5.478,0,0,0-1.078-.1,5.193,5.193,0,0,0-.681.044,5.463,5.463,0,0,0-4.288,3.159,5.377,5.377,0,0,0-.487,2.253,5.456,5.456,0,1,0,6.534-5.352Zm1.1,6.929a.522.522,0,0,1-.005.148.238.238,0,0,1-.009.055.548.548,0,0,1-.084.179v0l-1.637,2.182a.546.546,0,0,1-.873,0l-1.637-2.182v0a.553.553,0,0,1-.084-.179.474.474,0,0,1-.009-.055.513.513,0,0,1,0-.148.122.122,0,0,1,0-.035.178.178,0,0,1,.009-.024.1.1,0,0,1,.006-.041l1.637-5.456a.546.546,0,0,1,1.045,0l1.637,5.456c0,.013,0,.027.005.041a.19.19,0,0,1,.009.024.177.177,0,0,0-.005.04Zm0,0" transform="translate(-134.167 -178.909)" fill={color} />
            <path id="Path_1522" data-name="Path 1522" d="M126.739,164.851c-.031-.081-.062-.164-.1-.245-.084-.192-.174-.382-.273-.564-.008-.014-.014-.029-.021-.042-.109-.2-.231-.4-.359-.59-.04-.06-.086-.118-.128-.177-.088-.123-.177-.245-.273-.362-.055-.067-.109-.131-.164-.2q-.145-.169-.3-.327c-.051-.055-.1-.106-.154-.157-.144-.141-.292-.277-.446-.407-.015-.012-.029-.026-.043-.038q-.262-.215-.542-.405c-.046-.032-.094-.061-.141-.091q-.211-.139-.432-.262c-.063-.035-.126-.07-.19-.1q-.223-.118-.454-.218c-.055-.024-.105-.05-.16-.073q-.313-.131-.639-.234l-.1-.027q-.273-.082-.555-.143c-.066-.015-.132-.028-.2-.042-.192-.036-.387-.067-.584-.09l-.113-.014a6.986,6.986,0,0,0-.744-.04l-.054.01-.024,0a.912.912,0,0,0-.094,0c-.171,0-.34.011-.508.026-.071.006-.141.014-.212.022-.191.022-.379.051-.565.087-.058.011-.115.023-.173.036-.168.036-.333.08-.5.128-.124.036-.246.078-.368.12s-.23.08-.343.125-.2.082-.293.125c-.134.061-.267.124-.4.192-.072.038-.144.077-.215.117-.149.085-.3.173-.436.267-.051.034-.1.068-.153.1q-.231.16-.448.335l-.122.1c-.142.119-.279.245-.412.374-.043.042-.086.082-.128.126-.115.117-.224.24-.332.364-.054.067-.116.131-.17.2-.076.094-.146.193-.218.291s-.16.218-.233.332-.149.238-.218.36c-.045.079-.092.157-.134.236q-.136.256-.253.523c-.033.075-.062.152-.092.228q-.077.191-.142.388c-.033.1-.064.195-.093.294a7.639,7.639,0,1,0,14.433-.66Zm-7.1,9.339a6.555,6.555,0,0,1-6.548-6.548,6.461,6.461,0,0,1,.585-2.7,6.548,6.548,0,1,1,5.963,9.252Zm0,0" transform="translate(-104.359 -149.091)" fill={color} />
            <path id="Path_1523" data-name="Path 1523" d="M211.469,290.191l.854,1.139.854-1.139-.854-.57Zm0,0" transform="translate(-197.046 -269.869)" fill={color} />
            <path id="Path_1524" data-name="Path 1524" d="M7.187,22.14c-.105-.007-.21-.013-.314-.025s-.224-.033-.335-.051-.2-.034-.305-.055-.218-.055-.323-.084-.2-.054-.295-.084-.207-.074-.31-.113-.19-.071-.282-.109-.2-.094-.294-.142-.182-.088-.27-.137-.185-.109-.277-.168-.172-.1-.255-.164-.173-.128-.259-.194-.16-.12-.236-.183-.161-.145-.241-.218-.147-.132-.218-.2-.147-.16-.218-.242-.133-.145-.2-.221-.133-.176-.2-.265-.118-.155-.172-.236-.117-.191-.175-.287-.1-.164-.148-.249c-.055-.1-.1-.206-.149-.31-.041-.086-.085-.17-.122-.257-.046-.109-.083-.223-.123-.336-.03-.087-.066-.172-.093-.26-.037-.121-.065-.245-.095-.368-.021-.085-.046-.168-.064-.253-.029-.141-.046-.286-.066-.43-.01-.072-.025-.141-.032-.213a6.547,6.547,0,1,1,13.061-.665,6.653,6.653,0,0,1-.054.8c-.01.083-.026.164-.04.246q-.044.278-.112.551c-.011.045-.015.092-.028.137.1-.015.194-.023.29-.034.073-.009.144-.019.218-.026.212-.02.423-.032.634-.035,0-.014.007-.029.01-.043.05-.232.088-.466.116-.7.007-.054.011-.109.017-.164.023-.243.04-.487.04-.735a7.533,7.533,0,0,0-.605-2.982A7.639,7.639,0,1,0,6.992,23.226c.066-.2.141-.395.221-.588.025-.059.055-.117.079-.176.045-.1.087-.2.136-.3-.081,0-.16-.014-.24-.02Zm0,0" transform="translate(0.003 -7.431)" fill={color} />
            <path id="Path_1525" data-name="Path 1525" d="M214.717,245.764a.547.547,0,0,1,.3.092l.376.25-.679-2.263-.679,2.263.376-.25A.546.546,0,0,1,214.717,245.764Zm0,0" transform="translate(-199.44 -227.213)" fill={color} />
            <path id="Path_1526" data-name="Path 1526" d="M238.2,0a7.639,7.639,0,0,0-7.158,4.973c.046.116.081.236.121.353s.083.226.117.342c.05.164.088.327.127.495.026.109.055.215.078.325.036.185.06.374.085.563.012.093.03.184.039.277.028.283.042.569.042.857,0,.253-.015.506-.037.757-.005.067-.013.134-.02.2q-.036.321-.094.637c-.005.03-.008.062-.014.092.049.005.1.017.146.023.154.018.307.043.458.07A6.541,6.541,0,1,1,238.2,14.19c-.027,0-.054,0-.082,0,.029.049.055.1.081.15s.055.1.08.149q.149.281.276.571c.022.05.046.1.067.15.009.021.019.041.027.062A7.639,7.639,0,0,0,238.2,0Zm0,0" transform="translate(-215.281 -0.003)" fill={color} />
            <path id="Path_1527" data-name="Path 1527" d="M256.926,40.418c.077.03.155.058.231.09l.062.025c.1.044.2.094.3.141.071.034.143.065.213.1.055.028.109.058.164.088s.1.057.154.086l.062.034c.054.032.112.061.167.093.109.066.216.137.322.208.037.025.074.047.109.072.2.138.4.283.59.436l.047.039.005,0c.035.029.068.061.1.09.109.091.213.182.315.278.048.045.094.092.14.139l.118.118c.061.063.123.125.182.189s.118.134.176.2c.031.036.064.071.094.109s.043.047.063.072l.008.01a5.525,5.525,0,0,0,.889.069,5.449,5.449,0,1,0-4.761-2.787C256.766,40.352,256.845,40.387,256.926,40.418Zm5.065-2.218H260.9a1.637,1.637,0,0,1,0-3.274V34.38h1.091v.546a1.637,1.637,0,0,1,1.637,1.637h-1.091a.545.545,0,0,0-.546-.546H260.9a.546.546,0,1,0,0,1.091h1.091a1.637,1.637,0,0,1,0,3.274v.546H260.9v-.546a1.637,1.637,0,0,1-1.637-1.637h1.091a.546.546,0,0,0,.546.546h1.091a.546.546,0,0,0,0-1.091Zm0,0" transform="translate(-238.529 -30.015)" fill={color} />
            <path id="Path_1528" data-name="Path 1528" d="M98.171,96.55A.545.545,0,0,0,97.625,96H95.988V97.1h1.637A.545.545,0,0,0,98.171,96.55Zm0,0" transform="translate(-89.441 -89.456)" fill={color} />
            <path id="Path_1529" data-name="Path 1529" d="M37.862,50.888a8.6,8.6,0,0,1,1.012-1.22l.04-.038q.27-.269.562-.511c.026-.021.055-.042.079-.063q.273-.222.563-.421l.123-.084q.27-.177.551-.334c.058-.033.115-.066.173-.1.173-.092.351-.176.531-.256.074-.033.146-.069.218-.1.174-.071.354-.134.534-.2.075-.026.147-.055.223-.081.024-.059.049-.118.07-.177s.055-.145.076-.218c.034-.109.062-.216.088-.324.017-.069.036-.136.05-.205.025-.121.042-.245.059-.369.008-.059.019-.117.025-.176.018-.184.029-.369.029-.557a5.456,5.456,0,1,0-5.454,5.456C37.566,50.916,37.714,50.9,37.862,50.888Zm-.993-2.154H35.779v-.546a.546.546,0,0,1-.546-.546V43.278a.546.546,0,0,1,.546-.546v-.546H36.87v.546h.546v-.546h1.091v.646a1.621,1.621,0,0,1,.666,2.628,1.622,1.622,0,0,1-.666,2.628v.646H37.415v-.546H36.87Zm0,0" transform="translate(-29.777 -37.276)" fill={color} />
            <path id="Path_1530" data-name="Path 1530" d="M98.171,128.55a.545.545,0,0,0-.546-.546H95.988V129.1h1.637A.545.545,0,0,0,98.171,128.55Zm0,0" transform="translate(-89.441 -119.274)" fill={color} />
        </svg>
    );
};

export { HighCashIcon, LowCommissionIcon, SecurityIcon, SpeedIcon, OrderDiversityIcon, VarietyCryptoIcon };
