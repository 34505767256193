
const userTypes = {
    login: 'LOGIN',
    activeLogin: 'ACTIVE_LOGIN',
    refreshAccessToken: 'REFRESH_ACCESS_TOKEN',
    userLevelChange: 'USER_LEVEL_CHANGE',
    enableTwoFA: 'ENABLE_TWOFA',
    disableTwoFA: 'DISABLE_TWOFA',
    logout: 'LOGOUT',
};

export default userTypes;