
import React from 'react';
import { Route, Redirect } from 'react-router-dom';
import { useSnackbar } from 'notistack';
import { useSelector } from 'react-redux';

import RoutesPath from '../router/RoutesPath';

const PrivateRoute = ({ route }) => {

  const { enqueueSnackbar } = useSnackbar();

  const userData = useSelector(state => state.UserReducer);

  let showMessage = 'کاربر گرامی برای مشاهده این قسمت ابتدا وارد شوید';

  return (
    <Route
      exact={Boolean(route.exact)}
      path={route.path}
      render={(props) =>
        (userData.active && userData.access) ?
          <route.component {...props} routes={route.routes} />
          :
          <>
            <Redirect to={RoutesPath.login} />
            {enqueueSnackbar(showMessage, { variant: 'error' })}
          </>
      }
    />
  );
};

export default PrivateRoute;
