
import { useLocation } from 'react-router-dom';

const CheckRoute = (path, exact = true) => {

    const location = useLocation();

    let isCurrentRoute = false;
    let currentPath = location.pathname.replace(/\//g, "");
    let routePath = path.replace(/\//g, "");

    if (exact) {
        isCurrentRoute = Boolean(routePath === currentPath);
    } else {
        isCurrentRoute = currentPath.includes(routePath);
    };

    return isCurrentRoute;
};

export default CheckRoute;
