
import React from 'react';
import classes from './NavbarItem.module.scss';
import { useHistory } from 'react-router-dom';

import CheckRoute from '../../functions/CheckRoute';

const NavbarItem = ({ title, path }) => {

    const history = useHistory();

    let isCurrentRoute = CheckRoute(path, false);
    let className = (isCurrentRoute) ? 'activeTab' : 'disabledTab';

    const tabClick = () => {
        history.push(path);
    };

    return (
        <div
            className={`${classes.main} ${classes[className]}`}
            onClick={tabClick}
        >
            <span className='text-medium'>{title}</span>
        </div>
    );
};

export default NavbarItem;
