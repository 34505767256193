
import CryptoTypes from '../types/CryptoTypes';
import { currenciesPrimary } from '../../data/primaryCurrencies';

const initialState = {
    currencyList: currenciesPrimary,
    cryptoList: currenciesPrimary.filter((item) => item.symbol !== 'IRT'),
    convertFee: 0.2,                      //percent
    tradeLimit: 5,                        //usdt
    convertChangeLimit: 1000,             //usdt
    convertChangeLimitPercent: 0.5,       //percent
    loading: true,
    error: null
};

const CryptoReducer = (state = initialState, action) => {
    switch (action.type) {
        case CryptoTypes.setCryptoList:
            let cryptoList = action?.payload?.currencyList.filter((item) => item.symbol !== 'IRT');

            return {
                error: null,
                loading: false,
                currencyList: action?.payload?.currencyList,
                cryptoList,
                convertFee: action?.payload?.convertFee,
                tradeLimit: action?.payload?.tradeLimit,
                convertChangeLimit: action?.payload?.convertChangeLimit,
                convertChangeLimitPercent: action?.payload?.convertChangeLimitPercent,
            };

        case CryptoTypes.errorCrypto:
            return { ...state, loading: false, error: action.error };

        default:
            return state;
    };
};

export default CryptoReducer;