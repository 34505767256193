
import { combineReducers } from 'redux';

import ThemeReducer from './ThemeReducer';
import UserReducer from './UserReducer';
import TwoFAReducer from './TwoFAReducer';
import CryptoReducer from './CryptoReducer';
import PriceReducer from './PriceReducer';
import CryptoDetailReducer from './CryptoDetailReducer';
import CurrencyTimerReducer from './CurrencyTimerReducer';
import NotificationReducer from './NotificationReducer';
import AnnouncementReducer from './AnnouncementReducer';
import TradeMarketsReducer from './TradeMarketsReducer';
import TradeOrderReducer from './TradeOrderReducer';

const rootReducer = combineReducers({
    ThemeReducer,
    UserReducer,
    TwoFAReducer,
    CryptoReducer,
    PriceReducer,
    CryptoDetailReducer,
    CurrencyTimerReducer,
    NotificationReducer,
    AnnouncementReducer,
    TradeMarketsReducer,
    TradeOrderReducer,
});

export default rootReducer;
