
const RoutesPath = {
    home: '/',

    aboutUs: '/about-us',
    terms: '/terms',

    login: '/login',
    loginAuthenticator: '/login/authenticator',

    register: '/register',

    activeAccount: '/active-account',

    forgetPassword: '/forget-password',
    sendCodeForgetPassword: '/forget-password/send-code',
    activeCodeForgetPassword: '/forget-password/active-code',
    newPasswordForgetPassword: '/forget-password/new-password',

    newDevice: '/new-device',

    profile: '/profile',
    userInfo: '/profile/user-info',
    userLevel: '/profile/user-level',
    levelUpTwo: '/profile/user-level/level-two',
    levelUpThree: '/profile/user-level/level-three',

    referral: '/referral',

    security: '/security',
    changePassword: '/security/change-password',
    twoFA: '/security/two-fa',
    mobileTowFA: '/security/two-fa/mobile',
    googleAuthenticatorTowFA: '/security/two-fa/google-authenticator',
    disableTowFA: '/security/two-fa/disable',

    dashboard: '/dashboard',

    trade: '/trade',

    convert: '/convert',

    market: '/market',

    wallet: '/wallet',
    financialAssets: '/wallet/financial-assets',
    deposit: '/wallet/deposit',
    withdraw: '/wallet/withdraw',
    transactionHistory: '/wallet/transaction-history',

    order: '/order',

    help: '/help',

    bank: '/bank',
    bankAccounts: '/bank/bank-accounts',

    notification: '/notification',

    paymentWaiting: '/payment-waiting',
    paymentSuccess: '/payment-success',
    paymentFailed: '/payment-failed',

    mobileAppChart: '/mobile-chart',
};

export default RoutesPath;
