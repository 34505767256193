
import NotificationTypes from '../types/NotificationTypes';

export const setNewNotificationList = (newNotificationList) => {
    return {
        type: NotificationTypes.setNewNotificationList,
        newNotificationList,
    };
};

export const readNotification = (notificationId, isRead) => {
    return {
        type: NotificationTypes.readNotification,
        notificationId,
        isRead,
    };
};

export const readAllNotification = () => {
    return {
        type: NotificationTypes.readAllNotification,
    };
};

export const loadNotification = () => {
    return {
        type: NotificationTypes.loadNotification,
    };
};

export const errorNotification = (error) => {
    return {
        type: NotificationTypes.errorNotification,
        error,
    };
};