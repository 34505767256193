
import React from 'react';
import classes from './styles/SideMenu.module.scss';
import { useTheme } from '@material-ui/core/styles';
import useMediaQuery from '@material-ui/core/useMediaQuery';
import { useSelector } from 'react-redux';

import RoutesPath from '../router/RoutesPath';
import SideMenuItems from './components/SideMenuItems';
import { DashboardIcon, TradeIcon, ConvertIcon, MarketIcon, WalletIcon, HelpIcon } from '../icons/MenuIcons';
import { ActiveDashboardIcon, ActiveTradeIcon, ActiveConvertIcon, ActiveMarketIcon, ActiveWalletIcon, ActiveOrderIcon, ActiveHelpIcon } from '../icons/ActiveMenuIcons';

const helpMenuItem = {
    title: 'راهنما',
    route: RoutesPath.help,
    icon: <HelpIcon className={classes.icon} />,
    activeIcon: <ActiveHelpIcon className={classes.icon} />
};

const SideMenu = () => {

    const materialTheme = useTheme();

    const mobileMatch = useMediaQuery(materialTheme.breakpoints.down('xs'));

    const TradeMarketsList = useSelector(state => state.TradeMarketsReducer);

    const menuItems = [
        {
            title: 'داشبورد',
            route: RoutesPath.dashboard,
            rawRoute: RoutesPath.dashboard,
            icon: <DashboardIcon className={classes.icon} />,
            activeIcon: <ActiveDashboardIcon className={classes.icon} />
        },
        {
            title: 'مارکت',
            route: RoutesPath.market,
            rawRoute: RoutesPath.market,
            icon: <MarketIcon className={classes.icon} />,
            activeIcon: <ActiveMarketIcon className={classes.icon} />
        },
        {
            title: 'ترید',
            route: RoutesPath.trade + '/' + TradeMarketsList[0]?.value,
            rawRoute: RoutesPath.trade,
            icon: <TradeIcon className={classes.icon} />,
            activeIcon: <ActiveTradeIcon className={classes.icon} />
        },
        {
            title: 'تبدیل',
            route: RoutesPath.convert,
            rawRoute: RoutesPath.convert,
            icon: <ConvertIcon className={classes.icon} />,
            activeIcon: <ActiveConvertIcon className={classes.icon} />
        },
        {
            title: 'کیف پول',
            route: RoutesPath.wallet,
            rawRoute: RoutesPath.wallet,
            icon: <WalletIcon className={classes.icon} />,
            activeIcon: <ActiveWalletIcon className={classes.icon} />
        },
    ];

    return (
        <div>
            {mobileMatch ?
                <div className={classes.bottomMenu}>
                    {menuItems.map((item, index) => {
                        return (
                            <SideMenuItems
                                key={index}
                                title={item.title}
                                icon={item.icon}
                                activeIcon={item.activeIcon}
                                route={item.route}
                                rawRoute={item.rawRoute}
                            />
                        )
                    })}
                </div>
                :
                <div className={classes.rightMenu}>
                    <div>
                        {menuItems.map((item, index) => {
                            return (
                                <SideMenuItems
                                    key={index}
                                    title={item.title}
                                    icon={item.icon}
                                    activeIcon={item.activeIcon}
                                    route={item.route}
                                    rawRoute={item.rawRoute}
                                />
                            )
                        })}
                    </div>

                    {/* <div>
                        <SideMenuItems
                            title={helpMenuItem.title}
                            icon={helpMenuItem.icon}
                            activeIcon={helpMenuItem.activeIcon}
                            route={helpMenuItem.route}
                        />
                    </div> */}
                </div>
            }
        </div>
    );
};

export default SideMenu;
