
import React from 'react';

const DashboardIcon = ({ className }) => {
    return (
        <svg className={className} xmlns="http://www.w3.org/2000/svg" width="20.053" height="20.21" viewBox="0 0 20.053 20.21">
            <g id="Layer_3" data-name="Layer 3" transform="translate(-1.13 -1.05)">
                <g id="Rectangle_4238" data-name="Rectangle 4238" transform="translate(1.13 1.05)" fill="none" stroke="#e4e6eb" stroke-width="1">
                    <rect width="9.201" height="9.201" rx="2.53" stroke="none" />
                    <rect x="0.5" y="0.5" width="8.201" height="8.201" rx="2.03" fill="none" />
                </g>
                <g id="Rectangle_4239" data-name="Rectangle 4239" transform="translate(11.982 1.05)" fill="none" stroke="#e4e6eb" stroke-width="1">
                    <rect width="9.201" height="9.201" rx="2.53" stroke="none" />
                    <rect x="0.5" y="0.5" width="8.201" height="8.201" rx="2.03" fill="none" />
                </g>
                <g id="Rectangle_4240" data-name="Rectangle 4240" transform="translate(11.982 12.059)" fill="none" stroke="#e4e6eb" stroke-width="1">
                    <rect width="9.201" height="9.201" rx="2.53" stroke="none" />
                    <rect x="0.5" y="0.5" width="8.201" height="8.201" rx="2.03" fill="none" />
                </g>
                <g id="Rectangle_4241" data-name="Rectangle 4241" transform="translate(1.13 12.059)" fill="none" stroke="#e4e6eb" stroke-width="1">
                    <rect width="9.201" height="9.201" rx="2.53" stroke="none" />
                    <rect x="0.5" y="0.5" width="8.201" height="8.201" rx="2.03" fill="none" />
                </g>
            </g>
        </svg>
    );
};

const TradeIcon = ({ className }) => {
    return (
        <svg className={className} xmlns="http://www.w3.org/2000/svg" width="19.663" height="19.72" viewBox="0 0 19.663 19.72">
            <g id="trade_icon_wallet_deactive" data-name="trade icon wallet deactive" transform="translate(-92.7 -602.285)">
                <path id="Path_28645" data-name="Path 28645" d="M256.563,86.862V79.2H248.9Z" transform="translate(-144.7 523.585)" fill="none" stroke="#e4e6eb" stroke-width="1" />
                <path id="Path_28646" data-name="Path 28646" d="M248.9,79.2v7.662h7.662Z" transform="translate(-155.7 534.644)" fill="none" stroke="#d4d4d4" stroke-width="1" />
                <g id="Subtraction_298" data-name="Subtraction 298" transform="translate(10577.535 2903.833)" fill="#ff8f20">
                    <path d="M -10475.001953125 -2288.68603515625 C -10475.4453125 -2288.68603515625 -10475.8662109375 -2288.78271484375 -10476.2451171875 -2288.9560546875 L -10473.7578125 -2288.9560546875 C -10474.1376953125 -2288.78271484375 -10474.55859375 -2288.68603515625 -10475.001953125 -2288.68603515625 Z M -10477.7021484375 -2290.380859375 C -10477.8935546875 -2290.775634765625 -10478.0009765625 -2291.21826171875 -10478.0009765625 -2291.685546875 C -10478.0009765625 -2292.15283203125 -10477.8935546875 -2292.595458984375 -10477.7021484375 -2292.990234375 L -10477.7021484375 -2290.380859375 Z M -10472.283203125 -2290.41796875 L -10472.283203125 -2292.953125 C -10472.1025390625 -2292.56787109375 -10472.001953125 -2292.13818359375 -10472.001953125 -2291.685546875 C -10472.001953125 -2291.23291015625 -10472.1025390625 -2290.80322265625 -10472.283203125 -2290.41796875 Z M -10473.677734375 -2294.376953125 L -10476.3251953125 -2294.376953125 C -10475.92578125 -2294.57421875 -10475.4765625 -2294.68505859375 -10475.001953125 -2294.68505859375 C -10474.52734375 -2294.68505859375 -10474.078125 -2294.57421875 -10473.677734375 -2294.376953125 Z" stroke="none" />
                    <path d="M -10475.001953125 -2287.68603515625 C -10477.20703125 -2287.68603515625 -10479.0009765625 -2289.47998046875 -10479.0009765625 -2291.685546875 C -10479.0009765625 -2293.890869140625 -10477.20703125 -2295.68505859375 -10475.001953125 -2295.68505859375 C -10472.7958984375 -2295.68505859375 -10471.001953125 -2293.890869140625 -10471.001953125 -2291.685546875 C -10471.001953125 -2289.47998046875 -10472.7958984375 -2287.68603515625 -10475.001953125 -2287.68603515625 Z M -10476.7021484375 -2293.376953125 L -10476.7021484375 -2289.9560546875 L -10473.283203125 -2289.9560546875 L -10473.283203125 -2293.376953125 L -10476.7021484375 -2293.376953125 Z" stroke="none" fill="#d4d4d4" />
                </g>
            </g>
        </svg>
    );
};

const ConvertIcon = ({ className }) => {
    return (
        <svg className={className} xmlns="http://www.w3.org/2000/svg" width="20.575" height="22.604" viewBox="0 0 20.575 22.604">
            <g id="Layer_2" data-name="Layer 2" transform="translate(0.533 0.51)">
                <g id="Layer_1" data-name="Layer 1" transform="translate(0 2)">
                    <path id="Path_28618" data-name="Path 28618" d="M26.236,5.031,21.835.069a.211.211,0,0,0-.37.141V3.117a.211.211,0,0,1-.211.211H11.672A3.672,3.672,0,0,0,8,7H8V8.566a.213.213,0,0,0,.392.112A3.106,3.106,0,0,1,10.45,7.008L10.511,7H21.252a.211.211,0,0,1,.213.21v2.925a.213.213,0,0,0,.37.141l4.4-4.963a.213.213,0,0,0,0-.28Z" transform="translate(-6.775 -1.997)" fill="none" stroke="#e4e6eb" stroke-width="1" />
                    <path id="Path_28619" data-name="Path 28619" d="M.05,65.682l4.4,4.962a.211.211,0,0,0,.369-.141V67.6a.211.211,0,0,1,.213-.211h9.58a3.672,3.672,0,0,0,3.672-3.672h0V62.147a.213.213,0,0,0-.392-.112A3.127,3.127,0,0,1,15.836,63.7l-.06.009H5.034a.213.213,0,0,1-.213-.213V60.58a.211.211,0,0,0-.369-.141L.05,65.4a.208.208,0,0,0,0,.28Z" transform="translate(0.004 -51.131)" fill="none" stroke="#e4e6eb" stroke-width="1" />
                </g>
            </g>
        </svg>
    );
};

const MarketIcon = ({ className }) => {
    return (
        <svg className={className} xmlns="http://www.w3.org/2000/svg" width="20.514" height="20.514" viewBox="0 0 20.514 20.514">
            <g id="Layer_2" data-name="Layer 2" transform="translate(0.5 0.5)">
                <g id="Layer_1" data-name="Layer 1" transform="translate(-0.094 -0.133)">
                    <path id="Rectangle_4701" data-name="Rectangle 4701" d="M1,0H3.671a1,1,0,0,1,1,1V19.475a0,0,0,0,1,0,0H0a0,0,0,0,1,0,0V1A1,1,0,0,1,1,0Z" transform="translate(14.937 0.133)" fill="none" stroke="#e4e6eb" stroke-width="1" />
                    <path id="Rectangle_4702" data-name="Rectangle 4702" d="M1,0H3.706a1,1,0,0,1,1,1V14.576a0,0,0,0,1,0,0H0a0,0,0,0,1,0,0V1A1,1,0,0,1,1,0Z" transform="translate(7.516 5.049)" fill="none" stroke="#e4e6eb" stroke-width="1" />
                    <path id="Rectangle_4703" data-name="Rectangle 4703" d="M1,0H3.748a1,1,0,0,1,1,1V9.683a0,0,0,0,1,0,0H0a0,0,0,0,1,0,0V1A1,1,0,0,1,1,0Z" transform="translate(0.094 9.963)" fill="none" stroke="#e4e6eb" stroke-width="1" />
                </g>
            </g>
        </svg>
    );
};

const WalletIcon = ({ className }) => {
    return (
        <svg className={className} xmlns="http://www.w3.org/2000/svg" width="21.03" height="19.729" viewBox="0 0 21.03 19.729">
            <g id="noun_Wallet_2525404" transform="translate(0.5 0.803)">
                <g id="Group_7936" data-name="Group 7936" transform="translate(0 -0.303)">
                    <path id="Path_28164" data-name="Path 28164" d="M17.377,2.13a2.628,2.628,0,0,1,2.654,2.58V15.766a2.628,2.628,0,0,1-2.654,2.58l-14.8.08A2.612,2.612,0,0,1,0,15.846V2.136A2.328,2.328,0,0,1,2.359-.222L15.018-.3a2.268,2.268,0,0,1,2.064,1.327L2.432,1.1a.553.553,0,0,0,0,1.106ZM12.438,7.805h5.97V12.67h-5.97a1.557,1.557,0,0,1-1.548-1.548V9.353A1.608,1.608,0,0,1,12.438,7.805Z" transform="translate(0 0.303)" fill="none" stroke="#e5e5e5" stroke-width="1" fill-rule="evenodd" />
                </g>
                <g id="Group_7937" data-name="Group 7937" transform="translate(14.223 9.139)">
                    <path id="Path_28165" data-name="Path 28165" d="M248.253,127a1.179,1.179,0,0,1,0,2.359,1.182,1.182,0,1,1,0-2.359Z" transform="translate(-247 -127)" fill="#e5e5e5" fill-rule="evenodd" />
                </g>
            </g>
        </svg>
    );
};

const OrderIcon = ({ className }) => {
    return (
        <svg className={className} xmlns="http://www.w3.org/2000/svg" width="21" height="21" viewBox="0 0 21 21">
            <g id="Layer_2" data-name="Layer 2" transform="translate(0.5 0.5)">
                <g id="Layer_1" data-name="Layer 1" transform="translate(0.243)">
                    <rect id="Rectangle_4704" data-name="Rectangle 4704" width="20" height="20" rx="3" transform="translate(-0.243)" fill="none" stroke="#e4e6eb" stroke-miterlimit="10" stroke-width="1" />
                    <path id="Path_28623" data-name="Path 28623" d="M37.253,34l1.521,1.521-8.4,8.361L26.61,40.059l1.521-1.5,2.281,2.281Z" transform="translate(-22.817 -29.066)" fill="none" stroke="#e4e6eb" stroke-miterlimit="10" stroke-width="1" />
                </g>
            </g>
        </svg>
    );
};

const HelpIcon = ({ className }) => {
    return (
        <svg className={className} xmlns="http://www.w3.org/2000/svg" width="20" height="22" viewBox="0 0 20 22">
            <g id="Group_7941" data-name="Group 7941" transform="translate(-1320 -604)">
                <g id="Polygon_85" data-name="Polygon 85" transform="translate(1340 604) rotate(90)" fill="none">
                    <path d="M16.5,0,22,10,16.5,20H5.5L0,10,5.5,0Z" stroke="none" />
                    <path d="M 6.091276168823242 1 L 1.141275405883789 10 L 6.091276168823242 19 L 15.90872383117676 19 L 20.85872459411621 10 L 15.90872383117676 1 L 6.091276168823242 1 M 5.5 0 L 16.5 0 L 22 10 L 16.5 20 L 5.5 20 L 0 10 L 5.5 0 Z" stroke="none" fill="#e5e5e5" />
                </g>
                <g id="noun_Info_2442959" transform="translate(1295.286 609.919)">
                    <path id="Path_1193" data-name="Path 1193" d="M34.375,37.011a.751.751,0,0,1-.4.091.815.815,0,0,1-.324-.083.842.842,0,0,1-.5-1.015c.3-1.226.6-2.45.907-3.675a3.831,3.831,0,0,0,.09-.515.12.12,0,0,0-.16-.143.97.97,0,0,0-.2.07c-.149.063-.294.136-.445.194A.253.253,0,0,1,33,31.87v-.239a2.708,2.708,0,0,1,.344-.394c.45-.354.907-.692,1.374-1.029a1.547,1.547,0,0,1,.319-.16A.774.774,0,0,1,35.5,30a.9.9,0,0,1,.744,1.161q-.433,1.77-.869,3.541a4.738,4.738,0,0,0-.1.584.114.114,0,0,0,.165.129c.11-.033.214-.087.321-.13.129-.056.263-.108.393-.157a.19.19,0,0,1,.216.063.2.2,0,0,1,.021.232.86.86,0,0,1-.124.191,3.35,3.35,0,0,1-.35.338C35.42,36.35,34.475,36.956,34.375,37.011Z" transform="translate(0 -26.951)" fill="#e5e5e5" />
                    <path id="Path_1194" data-name="Path 1194" d="M43.62,2.492a1.238,1.238,0,1,1,.878-.362A1.241,1.241,0,0,1,43.62,2.492Z" transform="translate(-8.434 0)" fill="#e5e5e5" />
                </g>
            </g>
        </svg>
    );
};

export {
    DashboardIcon,
    TradeIcon,
    ConvertIcon,
    MarketIcon,
    WalletIcon,
    OrderIcon,
    HelpIcon,
};
