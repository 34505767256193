
import PriceTypes from '../types/PriceTypes';

const initialState = {
    usdtPrice: 0,
    irtPrice: 0,
    bitRatio: 1,
    decimalNumber: 3,
};

const PriceReducer = (state = initialState, action) => {
    switch (action.type) {
        case PriceTypes.setPriceList:
            return action.priceList;

        default:
            return state;
    };
};

export default PriceReducer;