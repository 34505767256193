
import TradeOrderTypes from '../types/TradeOrderTypes';

const initialState = {
    price: '',
    stopPrice: '',
    limitPrice: '',
    volume: '',
    total: '',
};

const TradeOrderReducer = (state = initialState, action) => {
    switch (action.type) {
        case TradeOrderTypes.priceChange:
            return { ...state, price: action.price };

        case TradeOrderTypes.stopPriceChange:
            return { ...state, stopPrice: action.stopPrice };

        case TradeOrderTypes.limitPriceChange:
            return { ...state, limitPrice: action.limitPrice };

        case TradeOrderTypes.volumeChange:
            return { ...state, volume: action.volume };

        case TradeOrderTypes.totalChange:
            return { ...state, total: action.total };

        case TradeOrderTypes.resetData:
            return initialState;

        default:
            return state;
    };
};

export default TradeOrderReducer;