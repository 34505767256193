
import React from 'react';
import classes from './Input.module.scss';
import classnames from 'classnames';

const Input = (
    {
        refrence,
        id,
        className,
        divClassName,
        inputClassName,
        codeInput = false,
        placeholder,
        type,
        textArea = false,
        autoFocus = false,
        label,
        hint,
        maxLength,
        disabled,
        error,
        value,
        onChange,
        onFocus,
        rightAdornment,
        leftAdornment,
    }) => {

    let errorClass = Boolean(error) && classes.errorClass;
    let adornmentClassName;
    if (rightAdornment) {
        adornmentClassName = classes.rightAdornmentInput;
    } else if (leftAdornment) {
        adornmentClassName = classes.leftAdornmentInput;
    };

    return (
        <div className={classnames(classes.main, className)}>
            {Boolean(label) &&
                <label
                    className={classes.label}
                    name={id}
                >
                    <span className={classnames('text-medium', classes.labelText)}>{label}</span>

                    {Boolean(hint) &&
                        <span className={classes.hintText}>{hint}</span>
                    }
                </label>
            }

            {textArea ?
                <textarea
                    ref={refrence}
                    className={classnames('text-medium', classes.textareaInput, errorClass, inputClassName)}
                    name={id}
                    ref={refrence}
                    type={type}
                    placeholder={placeholder}
                    autoFocus={autoFocus}
                    maxLength={maxLength}
                    disabled={disabled}
                    value={value}
                    onChange={onChange}
                />
                :
                <div className={classnames(classes.iconInputDiv, divClassName, errorClass)}>
                    <i tabIndex={-1} className={classes.inputIconLeft}>{leftAdornment}</i>
                    <i tabIndex={-1} className={classes.inputIconRight}>{rightAdornment}</i>
                    <input
                        ref={refrence}
                        className={
                            classnames(
                                'text-medium',
                                classes.input,
                                adornmentClassName,
                                inputClassName,
                                {
                                    [classes.codeInput]: codeInput,
                                }
                            )
                        }
                        name={id}
                        type={type}
                        placeholder={placeholder}
                        autoFocus={autoFocus}
                        maxLength={maxLength}
                        disabled={disabled}
                        value={value}
                        onChange={onChange}
                        onFocus={onFocus}
                    />
                </div>
            }

            {(Boolean(error) && error.length > 1) &&
                <span className={classnames('text-small', classes.errorText)}>{error}</span>
            }
        </div>
    );
};

export default Input;