
const setReloadExpiry = () => {
    const item = {
        value: 'true',
        expiry: new Date().getTime() + 10000,
    };

    localStorage.setItem('chunk_failed', JSON.stringify(item));
};

const getReloadExpiry = () => {
    const itemString = window.localStorage.getItem('chunk_failed');
    if (!itemString) return null;

    const item = JSON.parse(itemString);
    const isExpired = new Date().getTime() > item.expiry;

    if (isExpired) {
        localStorage.removeItem('chunk_failed');
        return null;
    };

    return item.value;
};

export { setReloadExpiry, getReloadExpiry };