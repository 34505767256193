
import TradeMarketsTypes from '../types/TradeMarketsTypes';

const initialState = [
    {
        label: 'USDT/IRT',
        value: 'USDT-IRT',
    }
];

const TradeMarketsReducer = (state = initialState, action) => {
    switch (action.type) {
        case TradeMarketsTypes.setTradeMarketsList:
            return action.tradeMarketsList

        default:
            return state;
    };
};

export default TradeMarketsReducer;