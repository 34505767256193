
import React, { useState, useEffect, Suspense } from 'react';
import '../styles/Theme.scss';
import classes from './styles/App.module.scss';
import { Switch, useLocation } from 'react-router-dom';
import { makeStyles } from '@material-ui/core/styles';
import { SnackbarProvider } from 'notistack';
import { useSelector, useDispatch } from 'react-redux';

import RoutesPath from '../router/RoutesPath';
import Routes from '../router/Routes';
import RouteCreator from '../router/RouteCreator';
import NavbarLogin from '../navbar/NavbarLogin';
import NavbarUser from '../navbar/NavbarUser';

import ErrorBoundary from './ErrorBoundary';
import TwoFADialog from '../components/twoFADialog/TwoFADialog';
import Loading from '../components/loading/Loading';
import RefreshToken from '../functions/RefreshToken';
import CryptoCallApi from '../functions/CryptoCallApi';
import CryptoDetailPriceCallApi from '../functions/CryptoDetailPriceCallApi';
import NotificationCallApi from '../functions/NotificationCallApi';
import AnnouncementCallApi from '../functions/AnnouncementCallApi';
import TradeMarketsCallApi from '../functions/TradeMarketsCallApi';
import { updateTime } from '../redux/actions/CurrencyTimerActions';

const App = () => {

  const snackbarClasses = useStyles();
  const location = useLocation();
  const reduxDispatch = useDispatch();

  const {
    ThemeReducer: theme,
    UserReducer: userData,
    TwoFAReducer: twoFADialogShow,
    CryptoReducer: cryptoReducer,
    CurrencyTimerReducer: currencyTimer,
  } = useSelector(state => state);

  const currencyList = cryptoReducer.currencyList;

  const [currentRoute, setCurrentRoute] = useState(RoutesPath.home);

  useEffect(() => {
    setCurrentRoute(location?.pathname);
  }, [location]);

  useEffect(() => {
    const getAccessToken = () => {
      reduxDispatch(RefreshToken());
    };

    let accessTokenInterval;
    if (userData.refresh) {
      accessTokenInterval = setInterval(getAccessToken, (5 * 60 * 1000));
    };

    return () => clearInterval(accessTokenInterval);
  }, [userData.refresh]);


  useEffect(() => {
    if (userData.active) reduxDispatch(NotificationCallApi());
  }, [userData.access, userData.active]);

  useEffect(() => {
    const getLoadAppData = () => {
      reduxDispatch(CryptoCallApi());
      reduxDispatch(AnnouncementCallApi());
      reduxDispatch(TradeMarketsCallApi());
    };

    getLoadAppData();

    // crisp
    window.$crisp = [];
    window.CRISP_WEBSITE_ID = "0cecf3e8-72ff-456a-b98b-70dd54f31256";

    (function () {
      var d = document;
      var s = d.createElement("script");

      s.src = "https://client.crisp.chat/l.js";
      s.async = 1;
      d.getElementsByTagName("head")[0].appendChild(s);
    })();
  }, []);

  useEffect(() => {
    const getNewCryptoList = () => {
      if (currencyTimer > 0) {
        reduxDispatch(updateTime());
      };

      if (currencyTimer === 0) {
        reduxDispatch(CryptoCallApi());
        reduxDispatch(CryptoDetailPriceCallApi());
      };
    };

    let cryptoListInterval = setInterval(getNewCryptoList, 1000);

    return () => clearInterval(cryptoListInterval);
  }, [currencyList, currencyTimer]);


  const navbarComponent = () => {
    let navbar = <NavbarUser />;

    if (currentRoute === RoutesPath.home || currentRoute.includes(RoutesPath.mobileAppChart)) {
      navbar = null;
    } else if (currentRoute.includes(RoutesPath.login) ||
      currentRoute.includes(RoutesPath.register) ||
      currentRoute.includes(RoutesPath.forgetPassword) ||
      currentRoute.includes(RoutesPath.newDevice)
    ) {
      navbar = <NavbarLogin />
    };

    return navbar;
  };

  let backgroundClassName = (currentRoute === RoutesPath.home) ? classes.homeTheme : theme;

  return (
    <ErrorBoundary>
      <Suspense fallback={<Loading isLoading />}>
        <SnackbarProvider
          className={snackbarClasses.root}
          classes={{
            variantSuccess: snackbarClasses.success,
            variantError: snackbarClasses.error,
          }}
          anchorOrigin={{
            vertical: 'top',
            horizontal: 'center',
          }}
          maxSnack={3}
          autoHideDuration={5000}
          preventDuplicate
        >
          <main className={`${theme} ${backgroundClassName} ${classes.main}`} >
            <TwoFADialog open={twoFADialogShow} />

            <div className={`${backgroundClassName} ${classes.backgroundDiv}`} />
            {navbarComponent()}

            <Switch>
              {Routes.map((route, index) => (
                <RouteCreator key={index} {...route} />
              ))}
            </Switch>
          </main>
        </SnackbarProvider>
      </Suspense>
    </ErrorBoundary>
  );
};

const useStyles = makeStyles({
  root: {
    fontFamily: 'IRANSans',
  },

  success: {
    color: '#1ade7c !important',
    backgroundColor: '#274737 !important',
  },

  error: {
    color: '#f84960 !important',
    backgroundColor: '#3f2629 !important',
  },
});

export default App;
