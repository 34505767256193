
import CallApi from './CallApi';
import { getAllCurrencyList } from '../api/CurrencyApi';
import { setCurrencyList, errorCrypto } from '../redux/actions/CryptoActions';
import { setPriceList } from '../redux/actions/PriceActions';
import { resetTime } from '../redux/actions/CurrencyTimerActions';
import { currenciesData } from '../data/primaryCurrencies';
import Consts from '../data/Consts';

const CryptoCallApi = () => {
    return async (dispatch, getState) => {
        dispatch(resetTime());

        let { UserReducer: userData } = getState();
        const isLoggedIn = (userData.active && Boolean(userData.access)) ? true : false;

        try {
            let response = await CallApi(getAllCurrencyList(isLoggedIn));

            let currencyListPrimary = response.result.currencies;
            let convertFee = response.result.trade_fee;
            let tradeLimit = response.result.minimum_trade;
            let convertChangeLimit = response.result.max_volume_for_change_price;
            let convertChangeLimitPercent = response.result.change_price_percent;

            let bitcoinData = currencyListPrimary.find((item) => item.symbol === 'BTC');
            let bitcoinPrice = bitcoinData?.usdt_price || Consts.defaultBitcoinPrice;

            let currencyList = [];
            let priceList = {};

            currencyListPrimary.map((item) => {
                item['logoimage'] = currenciesData[item?.symbol]?.logoimage;
                currencyList.push(item);

                let symbol = item.symbol;
                let usdtPrice = item.usdt_price || 1;
                let irtPrice = item.irt_price || 1;
                let bitRatio = (bitcoinPrice / usdtPrice).toExponential(1);

                let decimalNumber = (-1) * Math.floor(Math.log10(bitRatio * Consts.bitcoinValue));

                if (decimalNumber < 0) {
                    decimalNumber = 0;
                };

                if (symbol === 'IRT') {
                    decimalNumber = Consts.irtDecimal;
                };

                if (symbol === 'USDT') {
                    decimalNumber = Consts.usdDecimal;
                };

                priceList[symbol] = { usdtPrice, irtPrice, bitRatio, decimalNumber };
            });

            dispatch(setPriceList(priceList));
            dispatch(setCurrencyList({
                currencyList,
                convertFee,
                tradeLimit,
                convertChangeLimit,
                convertChangeLimitPercent,
            }));

        } catch (error) {
            console.log(error);
            let responseData = error?.response?.data;

            dispatch(errorCrypto(responseData));

        };
    };
};

export default CryptoCallApi;
