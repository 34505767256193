import React from 'react';
import classes from './Loading.module.scss';
import Dialog from '@material-ui/core/Dialog';
import Fade from '@material-ui/core/Fade';
import Lottie from 'react-lottie';

import loadingJson from '../../assets/animations/loading.json';

const Loading = ({ className, isLoading }) => {

    const lottieOptions = {
        loop: true,
        autoplay: true,
        animationData: loadingJson,
    };

    return (
        <Dialog
            classes={{ paper: classes.dialog }}
            open={isLoading}
            disableBackdropClick
            disableEscapeKeyDown
            BackdropProps={{ style: { backgroundColor: '#0f0f0f', opacity: 0.8 } }}
        >
            <Fade in={isLoading}>
                <div className={`${classes.mainDiv} ${className}`}>
                    <Lottie
                        height='30%'
                        width='30%'
                        options={lottieOptions}
                        isClickToPauseDisabled
                    />
                    {/* <span className={`text-xlarge ${classes.textSpan}`}>لطفا صبر کنید...</span> */}
                </div>
            </Fade>
        </Dialog>
    );
};

export default Loading;
