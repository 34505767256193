
const tradeOrderTypes = {
    priceChange: 'PRICE_CHANGE',
    stopPriceChange: 'STOP_PRICE_CHANGE',
    limitPriceChange: 'LIMIT_PRICE_CHANGE',
    volumeChange: 'VOLUME_CHANGE',
    totalChange: 'TOTAL_CHANGE',
    resetData: 'RESET_DATA',
};

export default tradeOrderTypes;