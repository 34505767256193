
import React from 'react';
import classes from './styles/SideMenuItems.module.scss';
import { useLocation, useHistory } from 'react-router-dom';

const SideMenuItems = ({ title, route, rawRoute, icon, activeIcon }) => {

    const location = useLocation();
    const history = useHistory();

    let className = (location.pathname.includes(rawRoute)) && 'activeTitle';

    const itemClick = () => {
        history.push(route);
    };

    return (
        <div className={classes.main} onClick={itemClick}>
            {(location.pathname.includes(rawRoute)) ? activeIcon : icon}
            <span className={`text-medium ${classes.title} ${classes[className]}`}>{title}</span>
        </div>
    );
};

export default SideMenuItems;
