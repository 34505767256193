
const ErrorResponseCode = {
    badRequest: 400,
    unauthorized: 401,
    forbidden: 403,
    twoFARequired: 406,
    timeout: 408,
    logout: 423,
};

export const defaultErrorMessage = {
    serverError: 'خطایی در سرور رخ داده است، مجددا تلاش کنید',
    captchaErrorLogin: 'خطا در ورود به سایت، مجددا تلاش کنید',
    captchaErrorRegister: 'خطا در ثبت نام، مجددا تلاش کنید',
    videoCancel: 'sending video canceled',
};

export default ErrorResponseCode;