
import TwoFATypes from '../types/TwoFATypes';

const initialState = false;

const TwoFAReducer = (state = initialState, action) => {
    switch (action.type) {
        case TwoFATypes.showDialogChange:
            return action.show;

        default:
            return state;
    };
};

export default TwoFAReducer;