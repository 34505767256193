
import React from 'react';
import classes from './Button.module.scss';
import ClipLoader from 'react-spinners/BeatLoader';
import classnames from 'classnames';

const Button = ({ children, className, disabled, type, iconButton = false, loading = false, onClick, ...rest }) => {

    return (
        <button
            className={
                classnames(
                    {
                        [classes.main]: !iconButton,
                        [classes.mainIconButton]: iconButton,
                    },
                    className,
                )}
            disabled={disabled}
            type={type}
            onClick={onClick}
            {...rest}
        >
            {children}

            <span className={classes.loaderSpan}>
                <ClipLoader
                    loading={loading}
                    size={6}
                    margin={1}
                />
            </span>
        </button>
    );
};

export default Button;
