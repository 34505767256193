
import CryptoDetailTypes from '../types/CryptoDetailTypes';
import { currencyTablePrimary } from '../../data/primaryCurrencies';

const initialState = {
    cryptoDetailList: currencyTablePrimary,
    favoriteList: [],
    loading: true,
    error: null
};

const CryptoDetailReducer = (state = initialState, action) => {
    switch (action.type) {
        case CryptoDetailTypes.setCryptoDetailList:
            return {
                error: null,
                loading: false,
                cryptoDetailList: action.cryptoDetailList,
                favoriteList: action.favoriteList,
            };

        case CryptoDetailTypes.updateCryptoDetailList:
            return {
                error: null,
                loading: false,
                cryptoDetailList: action.cryptoPricelList,
                favoriteList: action.favoriteList,
            };

        case CryptoDetailTypes.errorCryptoDetail:
            return { ...state, loading: false, error: action.error };

        case CryptoDetailTypes.changeFavorite: {
            let cryptoDetailListNew = [...state.cryptoDetailList];
            let currencyIndex = cryptoDetailListNew.findIndex((item) => item.pair_symbol === action.pairCurrency);
            cryptoDetailListNew[currencyIndex] = { ...cryptoDetailListNew[currencyIndex], favorite: action.isFavorite };

            let favoriteListNew = [...state.favoriteList];
            let favoriteIndex = favoriteListNew.findIndex((item) => item.pair_symbol === action.pairCurrency);

            if (favoriteIndex < 0) {
                if (action.isFavorite) favoriteListNew.push(cryptoDetailListNew[currencyIndex]);
            } else {
                if (!action.isFavorite) favoriteListNew.splice(favoriteIndex, 1);
            };

            return { ...state, cryptoDetailList: cryptoDetailListNew, favoriteList: favoriteListNew };
        };

        default:
            return state;
    };
};

export default CryptoDetailReducer;