
const statusCode = {
    accepted: 'accepted',
    waiting: 'waiting',
    rejected: 'rejected',
};

const transactionStatus = {
    success: { id: 1, title: 'انجام شده', valueId: 'com' },
    doing: { id: 2, title: 'در حال انجام', valueId: 'ong' },
    refused: { id: 3, title: 'رد شده', valueId: 'rej' },
};

const nextLevelStatusCode = {
    pending: 'pending',
    rejected: 'rejected',
};

const bankCardStatusCode = {
    accepted: 'ACP',
    waiting: 'WAT',
    rejected: 'REJ',
};

const socialMediaLinks = {
    blog: 'https://blog.valmart.net',
    telegram: 'https://t.me/Valmart_VLM',
    instagram: 'https://www.instagram.com/valmart_vlm',
    twitter: 'https://twitter.com/valmart_VLM',
    whatsapp: 'https://wa.me/989054345730',
};

const supportLinks = {
    blog: 'https://blog.valmart.net',
    support: 'https://support.valmart.net/',
    faq: 'https://support.valmart.net/',
    ticket: 'https://support.valmart.net/new-ticket',
    fees: 'https://support.valmart.net/fees',
    rewards: 'https://support.valmart.net/rewards',
    terms: 'https://valmart.net/terms',
    withdrawCrypto: 'https://support.valmart.net/withdraw-crypto',
    withdrawRial: 'https://support.valmart.net/withdraw-rial',
    depositCrypto: 'https://support.valmart.net/deposit-crypto',
    depositRial: 'https://support.valmart.net/deposit-rial',
};

const applicationLink = 'valmart://wallet';

const emptyTableTexts = {
    convertHistory: 'شما تاکنون تبدیلی انجام نداده‌اید',
}

const maxLevel = 3;

const twoFATypes = {
    google: 'google',
    mobile: 'sms',
};

const foreignTokens = {
    reCaptchaSitekey: '6LdIsG8cAAAAAJGJzZqVQQHthKba0p7283vsF3vM',
};

export {
    statusCode,
    transactionStatus,
    nextLevelStatusCode,
    bankCardStatusCode,
    socialMediaLinks,
    supportLinks,
    applicationLink,
    emptyTableTexts,
    maxLevel,
    twoFATypes,
    foreignTokens,
};