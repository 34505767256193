
import React from 'react';
import classes from './Drawer.module.scss';
import SwipeableDrawer from '@material-ui/core/SwipeableDrawer';
import MenuIcon from '@material-ui/icons/Menu';
import { useHistory } from 'react-router-dom';
import { useSelector } from 'react-redux';

import useLogin from '../../../hooks/useLogin';
import RoutesPath from '../../../router/RoutesPath';
import Button from '../../../components/button/Button';
import { supportLinks } from '../../../data/StaticData';
import { LogoWithoutName } from '../../../icons/LogoIcons';
import { DashboardIcon, MarketIcon, TradeIcon, ConvertIcon, WalletIcon } from '../../../icons/MenuIcons';
import { ReferralActiveIcon } from '../../../icons/CustomIcons';

const Drawer = ({ open, toggleDrawer, inHome = false }) => {

    const history = useHistory();

    const userData = useSelector(state => state.UserReducer);
    const TradeMarketsList = useSelector(state => state.TradeMarketsReducer);

    const isLoggedIn = useLogin();

    const menuOptions = [
        {
            id: 1,
            title: 'مارکت',
            icon: MarketIcon,
            route: RoutesPath.market,
            innerLink: true,
        },
        {
            id: 2,
            title: 'ترید',
            icon: TradeIcon,
            route: RoutesPath.trade + '/' + TradeMarketsList[0]?.value,
            innerLink: true,
        },
        {
            id: 3,
            title: 'تبدیل',
            icon: ConvertIcon,
            route: RoutesPath.convert,
            innerLink: true,
        },
    ];

    const menuOptionsLoggedIn = [
        {
            id: 1,
            title: 'داشبورد',
            icon: DashboardIcon,
            route: RoutesPath.dashboard,
            innerLink: true,
        },
        {
            id: 2,
            title: 'مارکت',
            icon: MarketIcon,
            route: RoutesPath.market,
            innerLink: true,
        },
        {
            id: 3,
            title: 'ترید',
            icon: TradeIcon,
            route: RoutesPath.trade + '/' + TradeMarketsList[0]?.value,
            innerLink: true,
        },
        {
            id: 4,
            title: 'تبدیل',
            icon: ConvertIcon,
            route: RoutesPath.convert,
            innerLink: true,
        },
        {
            id: 5,
            title: 'کیف پول',
            icon: WalletIcon,
            route: RoutesPath.wallet,
            innerLink: true,
        },
    ];

    const midOptions = [
        { id: 1, title: 'بلاگ', route: supportLinks.blog, innerLink: false },
        { id: 2, title: 'راهنما و پشتیبانی', route: supportLinks.support, innerLink: false },
        { id: 3, title: 'تیکت', route: supportLinks.ticket, innerLink: false },
        { id: 4, title: 'درباره ما', route: RoutesPath.aboutUs, innerLink: true },
    ];

    const footerOptions = [
        { id: 3, title: 'کارمزدها', route: supportLinks.fees, innerLink: false },
        { id: 1, title: 'پاداش‌ها', route: supportLinks.rewards, innerLink: false },
        { id: 2, title: 'قوانین و مقررات', route: RoutesPath.terms, innerLink: true },
    ];

    const levelupButton = () => {
        (toggleDrawer(false))();

        switch (Number(userData.level)) {
            case 0:
                history.push(RoutesPath.twoFA);
                break;
            case 1:
                history.push(RoutesPath.levelUpTwo);
                break;
            case 2:
                history.push(RoutesPath.levelUpThree);
                break;
            default:
                break;
        };
    };

    const menuItemButton = (route, isInnerLink) => {
        (toggleDrawer(false))();

        if (isInnerLink) {
            history.push(route);
        } else {
            window.open(route, '_blank');
        };
    };

    const registerButton = () => {
        (toggleDrawer(false))();
        history.push(RoutesPath.register);
    };

    const loginButton = () => {
        (toggleDrawer(false))();
        history.push(RoutesPath.login);
    };

    const referralButton = () => {
        (toggleDrawer(false))();
        history.push(RoutesPath.referral);
    };

    return (
        <div className={classes.main}>
            <div className={classes.optionsMenuDiv} onClick={toggleDrawer(true)}>
                <MenuIcon id={classes.menuIcon} />
            </div>

            <SwipeableDrawer
                anchor='right'
                open={open}
                onClose={toggleDrawer(false)}
                onOpen={toggleDrawer(true)}
            >
                <div className={classes.swipDrawer}>
                    <div className={classes.logoDiv}>
                        <LogoWithoutName className={classes.logoIcon} />

                        <div className={classes.logoTextsDiv}>
                            <span className={`text-large ${classes.logoTitleText}`}>وَلمارت</span>
                            <span className='text-medium'>معامله ارزان، امن و سریع رمزارزها</span>
                        </div>
                    </div>

                    {isLoggedIn &&
                        <>
                            <Button
                                className={classes.levelupButton}
                                onClick={levelupButton}
                            >
                                <span className='text-large'>ارتقا به سطح {Number(userData.level) + 1}</span>
                            </Button>

                            <Button
                                className={classes.referralButton}
                                onClick={referralButton}
                            >
                                <ReferralActiveIcon className={classes.referralIcon} />
                                <span className='text-large'>دعوت از دوستان</span>
                            </Button>
                        </>
                    }

                    <div className={classes.separator} />

                    {inHome &&
                        <>
                            {isLoggedIn ?
                                <div>
                                    {menuOptionsLoggedIn.map((item) =>
                                        <div
                                            key={item.id}
                                            className={classes.menuOptionsItemIconDiv}
                                            onClick={() => menuItemButton(item.route, item.innerLink)}
                                        >
                                            <item.icon className={classes.menuOptionIcons} />
                                            <span className='text-large'>{item.title}</span>
                                        </div>
                                    )}
                                </div>
                                :
                                <div>
                                    {menuOptions.map((item) =>
                                        <div
                                            key={item.id}
                                            className={classes.menuOptionsItemIconDiv}
                                            onClick={() => menuItemButton(item.route, item.innerLink)}
                                        >
                                            <item.icon className={classes.menuOptionIcons} />
                                            <span className='text-large'>{item.title}</span>
                                        </div>
                                    )}
                                </div>
                            }

                            <div className={classes.separator} />
                        </>
                    }

                    <div>
                        {midOptions.map((item) =>
                            <div
                                key={item.id}
                                className={classes.menuOptionsItemDiv}
                                onClick={() => menuItemButton(item.route, item.innerLink)}
                            >
                                <span className='text-large'>{item.title}</span>
                            </div>
                        )}
                    </div>

                    <div className={classes.separator} />

                    <div>
                        {footerOptions.map((item) =>
                            <div
                                key={item.id}
                                className={classes.menuOptionsItemDiv}
                                onClick={() => menuItemButton(item.route, item.innerLink)}
                            >
                                <span className='text-large'>{item.title}</span>
                            </div>
                        )}
                    </div>

                    <div className={classes.separator} />

                    {!isLoggedIn &&
                        <div>
                            <Button
                                className={classes.registerButton}
                                onClick={registerButton}
                            >
                                <span className='text-large text-bold'>ثبت نام</span>
                            </Button>

                            <Button
                                className={classes.loginButton}
                                onClick={loginButton}
                            >
                                <span className='text-large text-bold'>ورود</span>
                            </Button>
                        </div>
                    }
                </div>
            </SwipeableDrawer>
        </div>
    );
};

export default Drawer;
