
import CallApi from './CallApi';
import { getOrderBooks } from '../api/TradeApi';
import { setTradeMarketsList } from '../redux/actions/TradeMarketsActions';

const TradeMarketsCallApi = () => {
    return async (dispatch) => {

        try {
            let response = await CallApi(getOrderBooks());

            dispatch(setTradeMarketsList(response.result));

        } catch (error) {

        };
    };
};

export default TradeMarketsCallApi;
