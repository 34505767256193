
import React from 'react';
import classes from './styles/NavbarLogin.module.scss';
import AppBar from '@material-ui/core/AppBar';
import Toolbar from '@material-ui/core/Toolbar';
import { Link } from 'react-router-dom';
import { useSelector } from 'react-redux';

import RoutesPath from '../router/RoutesPath';
import { LogoWithoutName } from '../icons/LogoIcons';

const NavbarLogin = () => {

    const theme = useSelector(state => state.ThemeReducer);

    return (
        <AppBar position="static">
            <Toolbar classes={{ root: `${theme} ${classes.toolbarRoot}` }}>
                <Link className={`text-large ${classes.logoDiv}`} to={RoutesPath.home}>
                    <LogoWithoutName className={classes.logo} />
                    وَلمارت
                </Link>
            </Toolbar>
        </AppBar>
    );
};

export default NavbarLogin;