
import NotificationTypes from '../types/NotificationTypes';
import UserTypes from '../types/UserTypes';

const initialState = {
    unreadNotificationList: [],
    loading: true,
    error: null,
};

const NotificationReducer = (state = initialState, action) => {
    switch (action.type) {
        case NotificationTypes.setNewNotificationList:
            return {
                unreadNotificationList: action.newNotificationList,
                error: null,
                loading: false,
            };

        case NotificationTypes.loadNotification:
            return { ...state, loading: true, error: null };

        case NotificationTypes.readNotification: {
            let unreadNotificationList = [...state.unreadNotificationList];
            let notifIndex = unreadNotificationList.findIndex((item) => item.id === action.notificationId);

            if (notifIndex >= 0) {
                unreadNotificationList.splice(notifIndex, 1);
            };

            return { ...state, unreadNotificationList };
        };

        case NotificationTypes.readAllNotification: {
            return { unreadNotificationList: [], loading: false, error: null };
        };

        case NotificationTypes.errorNotification:
            return { ...state, loading: false, error: action.error };

        case UserTypes.logout:
            return initialState;

        default:
            return state;
    };
};

export default NotificationReducer;