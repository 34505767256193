
export const getAllNotifications = (page) => ({
  method: 'get',
  url: 'Notifications/All/',
  token: true,
  params: {
    page,
  },
});

export const getNewNotifications = () => ({
  method: 'get',
  url: 'Notifications/New/',
  token: true,
});

export const readNotification = (notification_id) => ({
  method: 'post',
  url: 'Notifications/Read/',
  token: true,
  data: {
    notification_id,
  },
});

export const readAllNotification = () => ({
  method: 'post',
  url: 'Notifications/ReadAll/',
  token: true,
});
