
import CallApi from './CallApi';
import { getAnnouncements } from '../api/AnnouncementApi';
import { setAnnouncementList } from '../redux/actions/AnnouncementActions';

const AnnouncementCallApi = () => {
    return async (dispatch) => {
        try {
            let response = await CallApi(getAnnouncements());

            setTimeout(() => {
                dispatch(setAnnouncementList(response?.result));
            }, [200])
        } catch (error) {

        };
    };
};

export default AnnouncementCallApi;
