
import { createStore, applyMiddleware, compose } from 'redux';
import thunk from 'redux-thunk';
import { persistReducer } from 'redux-persist';
import { CookieStorage } from 'redux-persist-cookie-storage';
import Cookies from 'js-cookie';
import { encryptTransform } from 'redux-persist-transform-encrypt';
import rootReducer from './reducers';

const encrypt_key = process.env.REACT_APP_ENCRYPT_KEY;
const domain = process.env.REACT_APP_DOMAIN;

const persistConfig = {
    key: '_userData',
    storage: new CookieStorage(Cookies, {
        expiration: {
            'default': (1 / 4),
        },
        setCookieOptions: {
            secure: true,
            domain,
        },
    }),
    whitelist: ['ThemeReducer', 'UserReducer'],
    transforms: [
        encryptTransform({
            secretKey: encrypt_key,
            onError: function () {
                window.location.reload();
            },
        }),
    ],
};

const persistedReducer = persistReducer(persistConfig, rootReducer);

const middleware = applyMiddleware(thunk);

const composeEnhancers =
    ((process.env.NODE_ENV === 'development' && typeof window !== 'undefined') &&
        window.__REDUX_DEVTOOLS_EXTENSION_COMPOSE__) || compose;

const Store = createStore(
    persistedReducer,
    composeEnhancers(middleware),
);

export default Store;
