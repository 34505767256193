
import CallApi from './CallApi';
import { priceList } from '../api/CurrencyApi';
import { updateCryptoDetailList, errorCryptoDetail } from '../redux/actions/CryptoDetailActions';
import { currenciesData } from '../data/primaryCurrencies';

const CryptoDetailPriceCallApi = () => {
    return async (dispatch, getState) => {
        let { UserReducer: userData } = getState();
        const isLoggedIn = (userData.active && Boolean(userData.access)) ? true : false;

        try {
            let response = await CallApi(priceList(isLoggedIn));

            let currencyPriceListPrimary = response.result;
            let currencyPriceList = [];
            let { CryptoDetailReducer: cryptoDetailReducer } = getState();
            let { cryptoDetailList: oldCryptoData } = cryptoDetailReducer;

            currencyPriceListPrimary.map((item) => {
                let currencyData = { ...item };
                currencyData['currency'] = currenciesData[currencyData?.pair_symbol?.split('/')[0]];
                currencyData['weekly_change'] = oldCryptoData.find((currency) => currency.pair_symbol === item.pair_symbol)?.weekly_change;
                currencyPriceList.push(currencyData);
            });

            let favoriteListNew = currencyPriceList.filter((item) => item.favorite === true);
            let favoriteList = favoriteListNew.map((currency) => {
                currency['weekly_change'] = oldCryptoData.find((item) => currency.pair_symbol === item.pair_symbol)?.weekly_change;
                return currency;
            });

            dispatch(updateCryptoDetailList(currencyPriceList, favoriteList));

        } catch (error) {
            let responseData = error?.response?.data;

            dispatch(errorCryptoDetail(responseData));
        };
    };
};

export default CryptoDetailPriceCallApi;
