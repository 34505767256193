
import React, { useState } from 'react';
import classes from './styles/NavbarUser.module.scss';
import AppBar from '@material-ui/core/AppBar';
import Toolbar from '@material-ui/core/Toolbar';
import { useTheme } from '@material-ui/core/styles';
import useMediaQuery from '@material-ui/core/useMediaQuery';
import { Link } from 'react-router-dom';
import { useSelector } from 'react-redux';

import UserQuickMenu from './components/userQuickMenu/UserQuickMenu';
import Drawer from './components/drawer/Drawer';
import RoutesPath from '../router/RoutesPath';
import { supportLinks } from '../data/StaticData';
import { LogoWithoutName } from '../icons/LogoIcons';

const NavbarUser = () => {

    const materialTheme = useTheme();

    const phoneMatch = useMediaQuery(materialTheme.breakpoints.down('xs'));

    const {
        ThemeReducer: theme,
        UserReducer: userData,
    } = useSelector(state => state);

    const [isDrawerOpen, setIsDrawerOpen] = useState(false);

    const toggleDrawer = (open) => (event) => {
        if (event && event.type === 'keydown' && (event.key === 'Tab' || event.key === 'Shift')) {
            return;
        };

        setIsDrawerOpen(open);
    };

    return (
        <AppBar position='fixed' className={classes.appBar}>
            <Toolbar classes={{ root: `${theme} ${classes.toolbarRoot}` }}>
                <div className={classes.mainDiv}>
                    {phoneMatch &&
                        <Drawer
                            open={isDrawerOpen}
                            toggleDrawer={toggleDrawer}
                        />
                    }

                    <Link className={`text-large ${classes.logoDiv}`} to={RoutesPath.home} >
                        <LogoWithoutName className={classes.logo} />
                        {phoneMatch &&
                            <span className='text-large'>وَلمارت</span>
                        }
                    </Link>

                    {!phoneMatch &&
                        <a
                            className={`text-large ${classes.blogButton}`}
                            href={supportLinks.blog}
                            target='_blank'
                            rel='noopener noreferrer'
                        >
                            بلاگ
                        </a>
                    }
                </div>

                <UserQuickMenu />
            </Toolbar>
        </AppBar>
    );
};

export default NavbarUser;