
import AannouncementTypes from '../types/AnnouncementTypes';

export const setAnnouncementList = (announcementList) => {
    return {
        type: AannouncementTypes.setAnnouncementList,
        announcementList,
    };
};

export const hideAnnouncement = (announcementId) => {
    return {
        type: AannouncementTypes.hideAnnouncement,
        announcementId,
    };
};
