
import React from 'react';
import { Route } from 'react-router-dom';

import PrivateRoute from './PrivateRoute';
import UserMenuRender from './UserMenuRender';

const RouteCreator = (route) => {

    return (
        <div style={{ width: '100%', height: '100%' }}>
            {Boolean(route.menu) ?
                <UserMenuRender hasTab={route.hasTab} tabs={route.routes || []} title={route.title}>
                    {Boolean(route.private) ?
                        <PrivateRoute route={route} />
                        :
                        <Route
                            exact={Boolean(route.exact)}
                            path={route.path}
                            render={props => (
                                <route.component {...props} routes={route.routes} />
                            )}
                        />
                    }
                </UserMenuRender>
                :
                <>
                    {Boolean(route.private) ?
                        <PrivateRoute route={route} />
                        :
                        <Route
                            exact={Boolean(route.exact)}
                            path={route.path}
                            render={props => (
                                <route.component {...props} routes={route.routes} />
                            )}
                        />
                    }
                </>
            }
        </div >
    );
};

export default RouteCreator;