
export const signin = (payload) => ({
  method: 'post',
  url: 'Signin/',
  data: {
    username: payload.username,
    password: payload.password,
  },
});

export const refreshToken = (refresh) => ({
  method: 'post',
  url: 'Signin/RefreshToken/',
  data: {
    refresh,
  },
});

export const logout = (refresh) => ({
  method: 'post',
  url: 'Signin/Signout/',
  token: true,
  data: {
    refresh,
  },
});
