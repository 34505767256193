
import React from 'react';

const WritingIcon = ({ className }) => {
    return (
        <svg className={className} xmlns="http://www.w3.org/2000/svg" width="32" height="35.706" viewBox="0 0 32 35.706">
            <g id="writing" transform="translate(-26.57)">
                <g id="Group_2934" data-name="Group 2934" transform="translate(26.57)">
                    <g id="Group_2933" data-name="Group 2933" transform="translate(0)">
                        <path id="Path_1120" data-name="Path 1120" d="M123.615,201.395A1.4,1.4,0,0,0,122.22,200H107.854a1.395,1.395,0,1,0,0,2.79H122.22A1.4,1.4,0,0,0,123.615,201.395Z" transform="translate(-100.888 -186.052)" fill="#c9c9c9" />
                        <path id="Path_1121" data-name="Path 1121" d="M107.854,280a1.395,1.395,0,0,0,0,2.79h8.725a1.395,1.395,0,0,0,0-2.79Z" transform="translate(-100.888 -260.473)" fill="#c9c9c9" />
                        <path id="Path_1122" data-name="Path 1122" d="M36.814,32.916H32.149a2.793,2.793,0,0,1-2.79-2.79V5.579a2.793,2.793,0,0,1,2.79-2.79H49.3a2.793,2.793,0,0,1,2.79,2.79v8.578a1.395,1.395,0,0,0,2.79,0V5.579A5.585,5.585,0,0,0,49.3,0H32.149A5.585,5.585,0,0,0,26.57,5.579V30.127a5.585,5.585,0,0,0,5.579,5.579h4.665a1.395,1.395,0,0,0,0-2.79Z" transform="translate(-26.57)" fill="#c9c9c9" />
                        <path id="Path_1123" data-name="Path 1123" d="M259.108,273.248a4.189,4.189,0,0,0-5.916,0l-7.657,7.641a1.4,1.4,0,0,0-.349.582l-1.668,5.49a1.395,1.395,0,0,0,1.707,1.75l5.63-1.559a1.4,1.4,0,0,0,.613-.357l7.641-7.627A4.189,4.189,0,0,0,259.108,273.248Zm-9.352,11.311-2.832.785.829-2.729,5.167-5.155,1.973,1.973Zm7.381-7.367-.27.27-1.973-1.973.269-.269a1.395,1.395,0,0,1,1.973,1.972Z" transform="translate(-228.332 -253.054)" fill="#c9c9c9" />
                        <path id="Path_1124" data-name="Path 1124" d="M122.22,120H107.854a1.395,1.395,0,1,0,0,2.79H122.22a1.395,1.395,0,0,0,0-2.79Z" transform="translate(-100.888 -111.631)" fill="#c9c9c9" />
                    </g>
                </g>
            </g>
        </svg>
    );
};

const ConvertIcon = ({ className }) => {
    return (
        <svg className={className} xmlns="http://www.w3.org/2000/svg" width="16.679" height="15.82" viewBox="0 0 16.679 15.82">
            <g id="change_icon" data-name="change icon" transform="translate(-1163.47 -1055.35)">
                <path id="Path_28167" data-name="Path 28167" d="M0,4.931c.3.059,3.057.062,6.068.052,3.494-.015,7.336-.052,7.336-.052L8.422,0" transform="translate(1179.441 1056.559) rotate(90)" fill="none" stroke-linecap="round" stroke-width="1" />
                <path id="Path_28168" data-name="Path 28168" d="M13.4.057C13.1,0,10.344,0,7.334,0,3.838.02,0,.057,0,.057L4.98,4.987" transform="translate(1169.164 1056.559) rotate(90)" fill="none" stroke-linecap="round" stroke-width="1" />
            </g>
        </svg>
    );
};

const AddCardIcon = ({ className }) => {
    return (
        <svg className={className} xmlns="http://www.w3.org/2000/svg" width="20.8512" height="16.9128" viewBox="0 0 17.376 14.094">
            <g id="add_card" data-name="add card" transform="translate(-37.5 -362.559)">
                <g id="Group_7976" data-name="Group 7976" transform="translate(47.874 363.059)">
                    <path id="Path_28183" data-name="Path 28183" d="M2359-3527v6.5" transform="translate(-2355.749 3527)" fill="none" stroke-linecap="round" stroke-width="1" />
                    <path id="Path_28184" data-name="Path 28184" d="M0,0V6.5" transform="translate(0 3.251) rotate(-90)" fill="none" stroke-linecap="round" stroke-width="1" />
                </g>
                <path id="Path_28185" data-name="Path 28185" d="M2318.226-3495h-5.879a1.443,1.443,0,0,0-.969.323,1.674,1.674,0,0,0-.378.826v7.492a1.186,1.186,0,0,0,.385.837,1.57,1.57,0,0,0,.708.365h10.472s1,.014,1.3-.475a2.289,2.289,0,0,0,.26-1.166v-2.674" transform="translate(-2273 3861.311)" fill="none" stroke-linecap="round" stroke-width="1" />
                <rect id="Rectangle_4278" data-name="Rectangle 4278" width="13.123" height="1.871" transform="translate(38 371.362)" fill="none" />
            </g>
        </svg>
    );
};

const DepositAsset = ({ className }) => {
    return (
        <svg className={className} xmlns="http://www.w3.org/2000/svg" width="19.816" height="18.469" viewBox="0 0 19.816 18.469">
            <g id="deposit" transform="translate(-205.112 -423.974)">
                <g id="Group_8501" data-name="Group 8501" transform="translate(-39.889 24.294)">
                    <path id="Path_28210" data-name="Path 28210" d="M-16364.776,3942.135v8.552a3.825,3.825,0,0,1-1.038,2.259,4.127,4.127,0,0,1-2.357,1.176" transform="translate(16628.594 -3536.971)" fill="none" stroke-width="2" />
                    <path id="Path_28365" data-name="Path 28365" d="M-16382.905,3942.135v8.552a3.825,3.825,0,0,0,1.038,2.259,4.127,4.127,0,0,0,2.357,1.176h11.121" transform="translate(16628.906 -3536.971)" fill="none" stroke-width="2" />
                </g>
                <g id="Group_8529" data-name="Group 8529" transform="translate(-212.39 544.339) rotate(-90)">
                    <path id="Path_28619" data-name="Path 28619" d="M.05,65.682l4.4,4.962a.211.211,0,0,0,.369-.141c0-2.963,0-9.924,0-9.924a.211.211,0,0,0-.369-.141L.05,65.4a.208.208,0,0,0,0,.28Z" transform="translate(108.117 361.869)" />
                    <path id="Path_28635" data-name="Path 28635" d="M15995.082,2520.677h-8.426" transform="translate(-15874.717 -2093.266)" fill="none" stroke-width="2" />
                </g>
            </g>
        </svg>
    );
};

const WithdrawAsset = ({ className }) => {
    return (
        <svg className={className} xmlns="http://www.w3.org/2000/svg" width="19.816" height="18.773" viewBox="0 0 19.816 18.773">
            <g id="withdraw" transform="translate(-205.112 -423.671)">
                <g id="Group_8501" data-name="Group 8501" transform="translate(-39.889 24.294)">
                    <path id="Path_28210" data-name="Path 28210" d="M-16364.776,3942.135v8.552a3.825,3.825,0,0,1-1.038,2.259,4.127,4.127,0,0,1-2.357,1.176" transform="translate(16628.594 -3536.971)" fill="none" stroke-width="2" />
                    <path id="Path_28365" data-name="Path 28365" d="M-16382.905,3942.135v8.552a3.825,3.825,0,0,0,1.038,2.259,4.127,4.127,0,0,0,2.357,1.176h11.121" transform="translate(16628.906 -3536.971)" fill="none" stroke-width="2" />
                </g>
                <g id="Group_8529" data-name="Group 8529" transform="translate(220.194 423.671) rotate(90)">
                    <path id="Path_28619" data-name="Path 28619" d="M.054,5.033,4.456.071a.211.211,0,0,1,.369.141c0,2.963,0,9.924,0,9.924a.211.211,0,0,1-.369.141L.054,5.313a.208.208,0,0,1,0-.28Z" transform="translate(0 0)" />
                    <path id="Path_28635" data-name="Path 28635" d="M8.227,0H0" transform="translate(3.826 5.172)" fill="none" stroke-width="2" />
                </g>
            </g>
        </svg>
    );
};

const ConvertAsset = ({ className }) => {
    return (
        <svg className={className} xmlns="http://www.w3.org/2000/svg" width="20.315" height="22.358" viewBox="0 0 20.315 22.358">
            <g id="convert" transform="translate(-151.113 -405.392)">
                <g id="Group_8608" data-name="Group 8608" transform="translate(151.112 405.392)">
                    <path id="Path_28619" data-name="Path 28619" d="M.05,65.4l4.4-4.962a.211.211,0,0,1,.369.141c0,2.963,0,9.924,0,9.924a.211.211,0,0,1-.369.141L.05,65.681a.208.208,0,0,1,0-.28Z" transform="translate(0.004 -60.368)" />
                    <path id="Path_28651" data-name="Path 28651" d="M1599.676,1241.547v-1.94c0-1.4-.348-3.244-2.765-3.244h-9.745" transform="translate(-1583.198 -1231.189)" fill="none" stroke-miterlimit="10" stroke-width="2" />
                </g>
                <g id="Group_8609" data-name="Group 8609" transform="translate(154.951 417.392)">
                    <path id="Path_28619-2" data-name="Path 28619" d="M4.767,65.682l-4.4,4.962A.211.211,0,0,1,0,70.5C0,67.54,0,60.58,0,60.58a.211.211,0,0,1,.369-.141l4.4,4.963a.208.208,0,0,1,0,.28Z" transform="translate(11.656 -60.357)" />
                    <path id="Path_28651-2" data-name="Path 28651" d="M1587.166,1236.363v1.94c0,1.4.348,3.244,2.765,3.244h9.745" transform="translate(-1587.167 -1236.363)" fill="none" stroke-miterlimit="10" stroke-width="2" />
                </g>
            </g>
        </svg>
    );
};

const UpdateAsset = ({ className }) => {
    return (
        <svg className={className} xmlns="http://www.w3.org/2000/svg" width="30" height="30" viewBox="0 0 30 30">
            <g id="update" transform="translate(4.129 24.678) rotate(-90)">
                <rect id="Rectangle_4717" data-name="Rectangle 4717" width="30" height="30" transform="translate(24.678 -4.129) rotate(90)" opacity="0" />
                <path id="Path_28625" data-name="Path 28625" d="M38.1.237a.215.215,0,0,1,.359-.176l4.867,4.582a.215.215,0,0,1,.026.282L39.383,10.3A.215.215,0,0,1,39,10.195" transform="translate(-29.156 -3.791)" />
                <path id="Path_28626" data-name="Path 28626" d="M11.674,14.66a9.678,9.678,0,1,0,9.683,9.676" transform="translate(-1.978 -13.489)" fill="none" stroke-miterlimit="10" stroke-width="2" />
            </g>
        </svg>
    );
};

const TradeAsset = ({ className }) => {
    return (
        <svg className={className} xmlns="http://www.w3.org/2000/svg" width="18.663" height="18.72" viewBox="0 0 18.663 18.72">
            <g id="trade_icon_wallet" data-name="trade icon wallet" transform="translate(-93.2 -602.785)">
                <path id="Path_28645" data-name="Path 28645" d="M256.563,86.862V79.2H248.9Z" transform="translate(-144.7 523.585)" />
                <path id="Path_28646" data-name="Path 28646" d="M248.9,79.2v7.662h7.662Z" transform="translate(-155.7 534.644)" />
                <g id="Subtraction_298" data-name="Subtraction 298" transform="translate(10577.535 2903.833)" >
                    <path d="M -10475.001953125 -2288.68603515625 C -10475.4453125 -2288.68603515625 -10475.8662109375 -2288.78271484375 -10476.2451171875 -2288.9560546875 L -10473.7578125 -2288.9560546875 C -10474.1376953125 -2288.78271484375 -10474.55859375 -2288.68603515625 -10475.001953125 -2288.68603515625 Z M -10477.7021484375 -2290.380859375 C -10477.8935546875 -2290.775634765625 -10478.0009765625 -2291.21826171875 -10478.0009765625 -2291.685546875 C -10478.0009765625 -2292.15283203125 -10477.8935546875 -2292.595458984375 -10477.7021484375 -2292.990234375 L -10477.7021484375 -2290.380859375 Z M -10472.283203125 -2290.41796875 L -10472.283203125 -2292.953125 C -10472.1025390625 -2292.56787109375 -10472.001953125 -2292.13818359375 -10472.001953125 -2291.685546875 C -10472.001953125 -2291.23291015625 -10472.1025390625 -2290.80322265625 -10472.283203125 -2290.41796875 Z M -10473.677734375 -2294.376953125 L -10476.3251953125 -2294.376953125 C -10475.92578125 -2294.57421875 -10475.4765625 -2294.68505859375 -10475.001953125 -2294.68505859375 C -10474.52734375 -2294.68505859375 -10474.078125 -2294.57421875 -10473.677734375 -2294.376953125 Z" stroke="none" />
                    <path d="M -10475.001953125 -2287.68603515625 C -10477.20703125 -2287.68603515625 -10479.0009765625 -2289.47998046875 -10479.0009765625 -2291.685546875 C -10479.0009765625 -2293.890869140625 -10477.20703125 -2295.68505859375 -10475.001953125 -2295.68505859375 C -10472.7958984375 -2295.68505859375 -10471.001953125 -2293.890869140625 -10471.001953125 -2291.685546875 C -10471.001953125 -2289.47998046875 -10472.7958984375 -2287.68603515625 -10475.001953125 -2287.68603515625 Z M -10476.7021484375 -2293.376953125 L -10476.7021484375 -2289.9560546875 L -10473.283203125 -2289.9560546875 L -10473.283203125 -2293.376953125 L -10476.7021484375 -2293.376953125 Z" stroke="none" />
                </g>
            </g>
        </svg>
    );
};

const LeftArrowConvert = ({ className }) => {
    return (
        <svg className={className} xmlns="http://www.w3.org/2000/svg" width="43.38" height="28.518" viewBox="0 0 43.38 28.518">
            <g id="arrow_convert" data-name="arrow convert" transform="translate(-1111.87 -271.5)">
                <text id="به" transform="translate(1146 286)" fill="#e4e6eb" font-size="14" font-family="IRANSans"><tspan x="-5.493" y="0">به</tspan></text>
                <line id="Line_621" data-name="Line 621" x1="41" transform="translate(1113.5 293.5)" fill="none" stroke="#e4e6eb" stroke-linecap="round" stroke-width="1.5" />
                <path id="Path_28630" data-name="Path 28630" d="M-6845.243-10439.493l5.454-5.454,5.454,5.454" transform="translate(11557.878 -6546.285) rotate(-90)" fill="none" stroke="#e4e6eb" stroke-linecap="round" stroke-width="1.5" />
            </g>
        </svg>
    );
};

const UserLevelLocation = ({ className }) => {
    return (
        <svg className={className} xmlns="http://www.w3.org/2000/svg" width="19.054" height="26.821" viewBox="0 0 19.054 26.821">
            <g id="location_other" data-name="location other" transform="translate(-21 -6.016)">
                <g id="Group_8546" data-name="Group 8546" transform="translate(21 6.016)">
                    <path id="Path_28623" data-name="Path 28623" d="M30.527,958.378A9.537,9.537,0,0,0,21,967.905c0,2.062,1.245,4.721,2.053,6.221l6.9,10.733a.657.657,0,0,0,1.15,0L38,974.126c.808-1.5,2.053-4.16,2.053-6.221A9.537,9.537,0,0,0,30.527,958.378Zm0,1.314a8.2,8.2,0,0,1,8.213,8.213,15.946,15.946,0,0,1-1.889,5.6l-6.324,9.666L24.2,973.5a15.95,15.95,0,0,1-1.889-5.6A8.2,8.2,0,0,1,30.527,959.692Zm0,3.285a4.928,4.928,0,1,0,4.928,4.928A4.938,4.938,0,0,0,30.527,962.977Zm0,1.314a3.614,3.614,0,1,1-3.614,3.614A3.6,3.6,0,0,1,30.527,964.291Z" transform="translate(-21 -958.378)" fill="#1ade7c" />
                </g>
            </g>
        </svg>
    );
};

const CautionIcon = ({ className }) => {
    return (
        <svg className={className} xmlns="http://www.w3.org/2000/svg" width="70" height="70" viewBox="0 0 70 70">
            <g id="warning_sign" data-name="warning sign" transform="translate(-537 -136)">
                <g id="Ellipse_1269" data-name="Ellipse 1269" transform="translate(537 136)" fill="none" stroke="#ff8f20" stroke-width="2">
                    <circle cx="35" cy="35" r="35" stroke="none" />
                    <circle cx="35" cy="35" r="34" fill="none" />
                </g>
                <path id="Path_28464" data-name="Path 28464" d="M0,0H6.118L5.246,31.143H.9Z" transform="translate(569 149)" fill="#ff8f20" />
                <circle id="Ellipse_1270" data-name="Ellipse 1270" cx="4" cy="4" r="4" transform="translate(568 186)" fill="#ff8f20" />
            </g>
        </svg>
    );
};

const UserIcon = ({ className }) => {
    return (
        <svg className={className} xmlns="http://www.w3.org/2000/svg" width="36" height="36" viewBox="0 0 36 36">
            <g id="user_prof" data-name="user prof" transform="translate(-22 -14)">
                <circle id="Ellipse_1279" data-name="Ellipse 1279" cx="3.143" cy="3.143" r="3.143" transform="translate(36.832 25.098)" fill="none" stroke="#d4d4d4" stroke-width="2" />
                <path id="Path_28665" data-name="Path 28665" d="M81.049,155.879l-.31-3.1a1.937,1.937,0,0,0-2.075-1.761H76.4L73.7,151H68.623a1.938,1.938,0,0,0-2.075,1.761l-.43,3.119Z" transform="translate(-33.707 -115.764)" fill="none" stroke="#d4d4d4" stroke-miterlimit="10" stroke-width="2" />
                <g id="Ellipse_1280" data-name="Ellipse 1280" transform="translate(24 16)" fill="none" stroke="#d4d4d4" stroke-width="2">
                    <circle cx="16" cy="16" r="16" stroke="none" />
                    <circle cx="16" cy="16" r="17" fill="none" />
                </g>
            </g>
        </svg>
    );
};

const BellIcon = ({ className }) => {
    return (
        <svg className={className} xmlns="http://www.w3.org/2000/svg" width="19.368" height="25.001" viewBox="0 0 19.368 25.001">
            <g id="bell_notif" data-name="bell notif" transform="translate(-2.5)">
                <g id="Layer_1" data-name="Layer 1" transform="translate(3.5)">
                    <path id="Path_28661" data-name="Path 28661" d="M20.868,25.282,18.3,22.843c-.018-4.307-.04-7.415,0-8.263a3.361,3.361,0,0,0-.231-1.487s-.16-.269-.3-.567c-.664-1.405-3.3-2.4-5.576-2.406h-.01c-2.291,0-4.912,1-5.573,2.406-.14.3-.3.567-.3.567a6.243,6.243,0,0,0-.231,1.487c.038.848.016,3.954,0,8.263L3.5,25.282v2.36H20.868Z" transform="translate(-3.5 -8.12)" fill="none" stroke="#d4d4d4" stroke-miterlimit="10" stroke-width="2" />
                    <line id="Line_626" data-name="Line 626" y1="1.564" transform="translate(8.654)" fill="none" stroke="#d4d4d4" stroke-miterlimit="10" stroke-width="4" />
                    <path id="Union_35" data-name="Union 35" d="M-16894.9,6150.219a3.215,3.215,0,0,1-3.129-3.206l6.361-.013a3.212,3.212,0,0,1-3.182,3.219Z" transform="translate(16903.531 -6125.218)" fill="#d4d4d4" />
                </g>
            </g>
        </svg>
    );
};

const ReferralIcon = ({ className, onClick }) => {
    return (
        <svg className={className} onClick={onClick} xmlns="http://www.w3.org/2000/svg" width="30.05" height="24" viewBox="0 0 30.05 24">
            <g id="Invite_friends" data-name="Invite friends" transform="translate(6.05)">
                <path id="Path_28337" data-name="Path 28337" d="M20,6a6,6,0,1,0-6,6,6,6,0,0,0,6-6ZM10,6a4,4,0,1,1,4,4A4,4,0,0,1,10,6Z" fill="#e4e6eb" />
                <path id="Path_28338" data-name="Path 28338" d="M17,14H11a7.008,7.008,0,0,0-7,7v2a1,1,0,0,0,2,0V21a5.006,5.006,0,0,1,5-5h6a5.006,5.006,0,0,1,5,5v2a1,1,0,0,0,2,0V21A7.008,7.008,0,0,0,17,14Z" fill="#e4e6eb" />
                <path id="Path_28340" data-name="Path 28340" d="M0,0V8.9" transform="translate(4 9.55) rotate(90)" fill="none" stroke="#e4e6eb" stroke-linecap="round" stroke-width="2.3" />
                <path id="Path_28341" data-name="Path 28341" d="M0,0V8.9" transform="translate(-0.45 5.1)" fill="none" stroke="#e4e6eb" stroke-linecap="round" stroke-width="2.3" />
            </g>
        </svg>
    );
};

const ReferralActiveIcon = ({ className, onClick }) => {
    return (
        <svg className={className} onClick={onClick} xmlns="http://www.w3.org/2000/svg" width="30.05" height="24" viewBox="0 0 30.05 24">
            <g id="Invite_friends" data-name="Invite friends" transform="translate(6.05)">
                <path id="Path_28337" data-name="Path 28337" d="M20,6a6,6,0,1,0-6,6,6,6,0,0,0,6-6ZM10,6a4,4,0,1,1,4,4A4,4,0,0,1,10,6Z" fill="#ff8f20" />
                <path id="Path_28338" data-name="Path 28338" d="M17,14H11a7.008,7.008,0,0,0-7,7v2a1,1,0,0,0,2,0V21a5.006,5.006,0,0,1,5-5h6a5.006,5.006,0,0,1,5,5v2a1,1,0,0,0,2,0V21A7.008,7.008,0,0,0,17,14Z" fill="#ff8f20" />
                <path id="Path_28340" data-name="Path 28340" d="M0,0V8.9" transform="translate(4 9.55) rotate(90)" fill="none" stroke="#ff8f20" stroke-linecap="round" stroke-width="2.3" />
                <path id="Path_28341" data-name="Path 28341" d="M0,0V8.9" transform="translate(-0.45 5.1)" fill="none" stroke="#ff8f20" stroke-linecap="round" stroke-width="2.3" />
            </g>
        </svg>
    );
};

const FilterIcon = ({ className, onClick }) => {
    return (
        <svg className={className} onClick={onClick} xmlns="http://www.w3.org/2000/svg" width="17.723" height="17.723" viewBox="0 0 17.723 17.723">
            <path id="Icon_awesome-filter" data-name="Icon awesome-filter" d="M16.891,0H.832A.831.831,0,0,0,.244,1.418l6.4,6.4v7.133A.831.831,0,0,0,7,15.634L9.77,17.572a.831.831,0,0,0,1.307-.681V7.821l6.4-6.4A.831.831,0,0,0,16.891,0Z" transform="translate(0)" />
        </svg>
    );
};

const WarningIcon = ({ className }) => {
    return (
        <svg className={className} id="warning" xmlns="http://www.w3.org/2000/svg" width="57.992" height="58.026" viewBox="0 0 57.992 58.026">
            <defs>
                <linearGradient id="linear-gradient" x1="0.5" x2="0.5" y2="1" gradientUnits="objectBoundingBox">
                    <stop offset="0" stop-color="#ff8f20" />
                    <stop offset="0.291" stop-color="#ff8f20" />
                    <stop offset="0.599" stop-color="#ead40c" />
                    <stop offset="1" stop-color="#f0df0b" />
                </linearGradient>
            </defs>
            <path id="Subtraction_1" data-name="Subtraction 1" d="M29,58.026a4.189,4.189,0,0,1-2.982-1.236L1.235,32a4.225,4.225,0,0,1,0-5.967L26.015,1.235a4.217,4.217,0,0,1,5.964,0L56.76,26.029a4.225,4.225,0,0,1,0,5.967L31.98,56.79A4.189,4.189,0,0,1,29,58.026Zm-2.17-16.215v4.344h4.341V41.811Zm0-29.941v25.5h4.34V11.87Z" transform="translate(-0.001 0)" fill="url(#linear-gradient)" />
        </svg>
    );
};

const NoRecordIcon = ({ className }) => {
    return (
        <svg className={className} xmlns="http://www.w3.org/2000/svg" width="61.447" height="79.519" viewBox="0 0 61.447 79.519">
            <g id="no_record_found" data-name="no record found" transform="translate(-0.47 -0.72)">
                <path id="Path_29027" data-name="Path 29027" d="M61.917,80.239H.47V.72H40.134L61.917,22.5Z" transform="translate(0)" fill="#2f3133" />
                <path id="Path_29028" data-name="Path 29028" d="M116.66.72V22.5h21.783Z" transform="translate(-76.526)" fill="#494a4b" />
                <line id="Line_633" data-name="Line 633" x2="37.998" transform="translate(12.193 38.144)" fill="#fff" />
                <line id="Line_635" data-name="Line 635" x2="35.74" transform="translate(14.23 38.145)" fill="none" stroke="#494a4b" stroke-miterlimit="10" stroke-width="2" stroke-dasharray="2 2.2" />
                <line id="Line_637" data-name="Line 637" x2="37.998" transform="translate(12.193 50.867)" fill="#fff" />
                <line id="Line_639" data-name="Line 639" x2="35.74" transform="translate(14.23 50.867)" fill="none" stroke="#494a4b" stroke-miterlimit="10" stroke-width="2" stroke-dasharray="2 2.2" />
                <line id="Line_641" data-name="Line 641" x2="37.998" transform="translate(12.193 63.593)" fill="#fff" />
                <line id="Line_643" data-name="Line 643" x2="35.74" transform="translate(14.23 63.593)" fill="none" stroke="#494a4b" stroke-miterlimit="10" stroke-width="2" stroke-dasharray="2 2.2" />
                <line id="Line_645" data-name="Line 645" x2="8.998" y2="8.998" transform="translate(9.137 9.176)" fill="#b79a99" stroke="#ff8f20" stroke-miterlimit="10" stroke-width="2" />
                <line id="Line_646" data-name="Line 646" x1="8.998" y2="8.998" transform="translate(9.137 9.176)" fill="#b79a99" stroke="#ff8f20" stroke-miterlimit="10" stroke-width="2" />
            </g>
        </svg>
    );
};

export {
    WritingIcon,
    ConvertIcon,
    AddCardIcon,
    DepositAsset,
    WithdrawAsset,
    ConvertAsset,
    UpdateAsset,
    TradeAsset,
    LeftArrowConvert,
    UserLevelLocation,
    CautionIcon,
    UserIcon,
    BellIcon,
    ReferralIcon,
    ReferralActiveIcon,
    FilterIcon,
    WarningIcon,
    NoRecordIcon,
};
