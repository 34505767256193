
import UserTypes from '../types/UserTypes';

const initialState = {
    refresh: null,
    access: null,
    firstName: null,
    lastName: null,
    avatar: null,
    username: null,
    email: null,
    mobile: null,
    level: 0,
    loginType: null,
    lastLoginDate: null,
    lastLoginTime: null,
    ip: null,
    is2FAEnabled: false,
    twoFAType: null,
    active: false,
};

const UserReducer = (state = initialState, action) => {
    switch (action.type) {
        case UserTypes.login: {
            let userData = {
                refresh: action.payload.refresh,
                access: action.payload.access,
                email: action.payload.user['email'],
                mobile: action.payload.user['mobile_number'],
                firstName: action.payload.user['first_name'],
                lastName: action.payload.user['last_name'],
                loginType: action.payload.user.logined_field,
                level: action.payload.user['level'],
                avatar: action.payload.user['avatar'],
                lastLoginDate: action.payload.user['last_login'][0],
                lastLoginTime: action.payload.user['last_login'][1],
                ip: action.payload.user['ip'],
                is2FAEnabled: action.payload.twofa_verify.need,
                twoFAType: action.payload.twofa_verify.twofa_type,
                username: action.payload.username,
                active: action.payload?.active || false,
            };

            return userData;
        };

        case UserTypes.activeLogin:
            return { ...state, active: true };

        case UserTypes.refreshAccessToken:
            return { ...state, access: action.access };

        case UserTypes.userLevelChange:
            return { ...state, level: action.level };

        case UserTypes.enableTwoFA:
            return {
                ...state,
                level: 1,
                is2FAEnabled: true,
                twoFAType: action.twoFAType,
                mobile: action.mobile,
            };

        case UserTypes.disableTwoFA:
            return {
                ...state,
                level: 0,
                is2FAEnabled: false,
                twoFAType: null,
            };

        case UserTypes.logout:
            return initialState;

        default:
            return state;
    };
};

export default UserReducer;