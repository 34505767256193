
import React from 'react';
import classes from './ErrorMessage.module.scss';
import CancelIcon from '@material-ui/icons/Cancel';

const ErrorMessage = ({ message, className }) => {

    let hiddenClassName = !Boolean(message) && classes.hiddenMessage;

    return (
        <div className={`${classes.main} ${hiddenClassName} ${className}`}>
            <CancelIcon id={classes.errorIcon} />
            <span className='text-medium'>{message}</span>
        </div>
    );
};

export default ErrorMessage;