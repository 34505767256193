
import CallApi from './CallApi';
import { getNewNotifications } from '../api/NotificationApi';
import { setNewNotificationList, loadNotification, errorNotification } from '../redux/actions/NotificationActions';

const NotificationCallApi = () => {
    return async (dispatch) => {
        dispatch(loadNotification());

        try {
            let response = await CallApi(getNewNotifications());

            dispatch(setNewNotificationList(response.result));
        } catch (error) {
            let responseStatus = error?.response?.status;
            let responseData = error?.response?.data;

            dispatch(errorNotification(responseData));
        };
    };
};

export default NotificationCallApi;
