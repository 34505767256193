
import CurrencyTimerTypes from '../types/CurrencyTimerTypes';

const initialState = 8;

const CurrencyTimerReducer = (state = initialState, action) => {
    switch (action.type) {
        case CurrencyTimerTypes.resetTime:
            return initialState;

        case CurrencyTimerTypes.updateTime:
            return (state - 1);

        default:
            return state;
    };
};

export default CurrencyTimerReducer;