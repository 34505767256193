
import React from 'react';
import classes from './styles/NavbarMenuWithTabs.module.scss';

import NavbarItem from './navbarItem/NavbarItem';

const NavbarMenuWithTabs = ({ tabs = [], title }) => {

    let hiddenClass = (tabs.length === 0) && classes.hidden;

    return (
        <div className={`${classes.main} ${hiddenClass}`}>
            <span className={`text-small ${classes.titleSpan}`}>{title}</span>
            <div className={classes.tabsDiv}>
                {tabs.map((item, index) =>
                    <NavbarItem
                        key={index}
                        title={item.title}
                        path={item.path}
                    />
                )}
            </div>
        </div>
    );
};

export default NavbarMenuWithTabs;
