
import CallApi from './CallApi';
import { refreshToken } from '../api/LoginApi';
import { refreshAccessToken, logout } from '../redux/actions/UserActions';
import ErrorResponseCode from '../data/ErrorResponseCode';

const RefreshToken = (timeout = false) => {
    return async (dispatch, getState) => {
        let { UserReducer } = getState();
        let prevRefreshToken = UserReducer?.refresh;

        try {
            let response = await CallApi(refreshToken(prevRefreshToken));
            let accessToken = response?.access || null;

            dispatch(refreshAccessToken(accessToken));
            if (timeout) window.location.reload();
        } catch (error) {
            let responseStatus = error?.response?.status;

            if (responseStatus === ErrorResponseCode.logout) dispatch(logout());
        };
    };
};

export default RefreshToken;
