
import React from 'react';
import classes from './styles/UserMenuRender.module.scss';
import { useSelector } from 'react-redux';

import SideMenu from '../menu/SideMenu';
import NavbarMenuWithTabs from '../navbar/NavbarMenuWithTabs';
import NavbarMenuWithoutTabs from '../navbar/NavbarMenuWithoutTabs';

const UserMenuRender = ({ children, tabs, title, hasTab }) => {

  const theme = useSelector(state => state.ThemeReducer);

  return (
    <div className={`${theme} ${classes.main}`}>
      <SideMenu />

      <div className={classes.dataDiv}>
        {Boolean(title) ?
          Boolean(hasTab) ?
            <NavbarMenuWithTabs tabs={tabs} title={title} />
            :
            <NavbarMenuWithoutTabs title={title} />
          :
          null
        }
        {children}
      </div>
    </div>
  );
};

export default UserMenuRender;
