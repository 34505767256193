
import UserTypes from '../types/UserTypes';

export const login = (payload) => {
    return {
        type: UserTypes.login,
        payload,
    };
};

export const activeLogin = () => {
    return {
        type: UserTypes.activeLogin,
    };
};

export const refreshAccessToken = (access) => {
    return {
        type: UserTypes.refreshAccessToken,
        access,
    };
};

export const userLevelChange = (level) => {
    return {
        type: UserTypes.userLevelChange,
        level,
    };
};

export const enableTwoFA = (twoFAType, mobile) => {
    return {
        type: UserTypes.enableTwoFA,
        twoFAType,
        mobile,
    };
};

export const disableTwoFA = () => {
    return {
        type: UserTypes.disableTwoFA,
    };
};

export const logout = () => {
    return {
        type: UserTypes.logout,
    };
};