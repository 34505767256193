
import React from 'react';
import classes from './styles/NavbarMenuWithoutTabs.module.scss';

const NavbarMenuWithoutTabs = ({ title }) => {

    return (
        <div className={classes.main}>
            <span className={`text-small ${classes.titleSpan}`}>{title}</span>
        </div>
    );
};

export default NavbarMenuWithoutTabs;
