
export const getAnnouncements = () => ({
  method: 'get',
  url: 'Announcements/',
});

export const getNews = () => ({
  method: 'get',
  url: 'Announcements/news/',
});
